import { createRouter, createWebHistory } from 'vue-router'
import _Store from '@/store'

// MODULES

import Login from 'ROUTER/modules/login.js'
// Catalogs
import Countries from 'ROUTER/modules/countries.js'
import States from 'ROUTER/modules/states.js'
import Cities from 'ROUTER/modules/cities.js'
import Languages from 'ROUTER/modules/languages.js'
import Colors from 'ROUTER/modules/colors.js'
import Vehicle_brands from 'ROUTER/modules/vehicleBrands.js'
import Vehicle_models from 'ROUTER/modules/vehicleModels.js'
import Amenities from 'ROUTER/modules/amenities.js'
import PartnerCategories from 'ROUTER/modules/partnerCategories.js'
import Services from 'ROUTER/modules/services.js'
import Skills from 'ROUTER/modules/skills.js'
import Faqs from 'ROUTER/modules/faqs.js'
import Faq_sections from 'ROUTER/modules/faqSections.js'
import Users from 'ROUTER/modules/users.js'
import Travellers from 'ROUTER/modules/travellers.js'
import Banks from 'ROUTER/modules/banks.js'
import Local_guides from 'ROUTER/modules/localGuides.js'
import Documents from "ROUTER/modules/documents.js";
import SupportChat from 'ROUTER/modules/supportChat.js'
import Partners from 'ROUTER/modules/partners.js'
import ExperienceCategories from 'ROUTER/modules/experiencesCategories.js'
import ExperienceDuration from 'ROUTER/modules/experiencesDurations.js'
import Experiences from 'ROUTER/modules/experiences.js'
import Currencies from 'ROUTER/modules/currencies.js'
import Reservations from 'ROUTER/modules/reservations.js';
import Reports from 'ROUTER/modules/reports.js'


const routes = [
    ...Login,
    ...Countries,
    ...States,
    ...Cities,
    ...Languages,
    ...Colors,
    ...Vehicle_brands,
    ...Vehicle_models,
    ...Amenities,
    ...PartnerCategories,
    ...Services,
    ...Skills,
    ...Faqs,
    ...Faq_sections,
    ...Users,
    ...Travellers,
    ...Banks,
    ...Local_guides,
    ...Documents,
    ...SupportChat,
    ...Partners,
    ...ExperienceCategories,
    ...ExperienceDuration,
    ...Experiences,
    ...Currencies,
    ...Reservations,
    ...Reports,
    {
        path: '/:pathMatch(.*)*',
        component: () => import(/* webpackChunkName: "about" */ 'COMPONENTS/Page404.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


// Router before each validations
router.beforeEach( async(to, from, next) => {
    // console.log(to, 'to')
    // console.log(from, 'from')

    /*** CHECK SESSION INTERNAL ***/
    let session = await _Store.dispatch('existSession')
    //console.log('BEFORE EACH ROUTER SESSION:', session)
    let user = null;
    if(session) {
        user = _Store.getters.getUser;
    }

    
    // Hidden views in specifics cases
    const session_views = ['countries']
    const no_session_views = ['login', 'recovery']

    if (session == null && session === undefined && to.name !== 'login') {
        next({name: 'login'})
    }

    else if(session && user.rol_name === 'partner' && to.name !== 'experiences') {
        next({name: 'experiences'});
    }

    else if((session && user.rol_name === 'administrator' && to.name === 'login') || (session && user.rol_name === 'partner' && to.name === 'login')) {
        console.log('here')
        next({
            name: user.rol_name === 'administrator' ? 'countries' : 'experiences'
        })
    }
    else {
        next();
    }


})

export default router
