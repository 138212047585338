import sendRequest from "@/libs/sendRequest"

const partners = {
    namespaced: true,
    state: {
        partner: {},
        partners: [],
        id_partner: null,
        error: false,
        last_page: null,
        total_pages: null
    },
    getters: {
        getPartner: (state) => (state.partner),
        getPartners: (state) => (state.partners),
        getIdPartner:(state) => (state.id_partner),
        getLastPage: (state) => (state.last_page),
        getTotalPages: (state) => (state.total_pages),
        getError: (state) => (state.error)
    },
    mutations: {
        setPartner: (state, payload) => state.partner = payload,
        setPartners: (state, payload) => state.partners = payload,
        setIdPartner: (state, payload) => state.id_partner = payload,
        setLastPage: (state, payload) => state.last_page = payload,
        setTotalPages: (state, payload) => state.total_pages = payload,
        setError: (state, payload) => state.error = payload,
    },
    actions: {
        list: async({commit, dispatch, rootGetters}, query) => {
            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/users/partners/list', 'GET', null, query, custom_headers)

            if(response.success && response.data) {
                if(query.datatable) {
                    commit('setPartners', response.data.list.result)
                    commit('setTotalPages', response.data.list.total_pages)

                    if(response.data.list.total_pages == query.page) {
                        commit('setLastPage', true)
                    }
                    else {
                        commit('setLastPage', false)
                    }
                }
                else {
                    commit('setPartners', response.data.list)
                }
                dispatch('hiddenLoading', null, { root: true })
            }
            else if(!response.success && response.errors) {
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', 'An error has occurred try later', { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        view: async({commit, dispatch, rootGetters}, query) => {
            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/users/partners/view', 'GET', null, query, custom_headers)

            if(response.success && response.data) {
                commit('setPartner', response.data.view)
                dispatch('hiddenLoading', null, { root: true })
            }
            else if(!response.success && response.errors) {
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', 'An error has occurred try later', { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        createPartner: async({commit, dispatch, rootGetters}, payload) => {
            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/users/partners/create_partner', 'PUT', payload.body, payload.query, custom_headers)

            if(response.success && response.data) {
                dispatch('showAlert', 'Info saved successfully', { root: true })
                dispatch('hiddenLoading', null, { root: true })
                commit('setIdPartner', response.data.id)
                commit('setError', false)
            }
            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    switch(response.errors[error].code) {
                        case 'error_empty_id_city':
                            messages.push('Error: city required.')
                            break
                        case 'error_empty_id_partner_category':
                            messages.push('Error: partner category required.')
                            break
                        case 'error_empty_first_name':
                            messages.push('Error: first name required.')
                            break
                        case 'error_empty_address':
                            messages.push('Error: address required.')
                            break
                        case 'error_empty_zip_code':
                            messages.push('Error: zip code required.')
                            break
                        case 'error_empty_business_name':
                            messages.push('Error: business name')
                            break
                        case 'error_empty_picture':
                            messages.push('Error: business name')
                            break
                        case 'error_empty_languages':
                            messages.push('Error: languages required.')
                            break
                        default:
                            messages.push('An error has occurred try later')
                            break
                    }
                }
                commit('setError', true)
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', messages, { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        addAccess: async({commit, dispatch, rootGetters}, data) => {
            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/users/partners/update_partner_access', 'PUT', data.body, data.query, custom_headers)

            if(response.success && response.data) {
                dispatch('showAlert', 'Info saved successfully', { root: true })
                dispatch('hiddenLoading', null, { root: true })
                commit('setError', false)
            }
            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    switch(response.errors[error].code) {
                        case 'error_empty_email':
                            messages.push('Error: Email required.')
                            break
                        case 'error_empty_phone':
                            messages.push('Error: first phone required.')
                            break
                        case 'error_empty_password':
                            messages.push('Error: password required.')
                            break
                        case 'error_empty_confirm_password':
                            messages.push('Error: confirm password required.')
                            break
                        case 'error_passwords_not_match':
                            messages.push('Error: passwords must match')
                            break
                        default:
                            messages.push('An error has occurred try later')
                            break
                    }
                }
                commit('setError', true)
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', messages, { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        addFinances: async({commit, dispatch, rootGetters}, data) => {
            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/users/partners/update_partner_finance', 'PUT', data.body, data.query, custom_headers)

            if(response.success && response.data) {
                dispatch('showAlert', 'Info saved successfully', { root: true })
                dispatch('hiddenLoading', null, { root: true })
                commit('setError', false)
            }
            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    switch(response.errors[error].code) {
                        case 'error_empty_id_bank':
                            messages.push('Error: Bank required.')
                            break
                        case 'error_empty_code':
                            messages.push('Error: Interbank code required.')
                            break
                        default:
                            messages.push('An error has occurred try later')
                            break
                    }
                }
                commit('setError', true)
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', messages, { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        addDocuments: async({commit, dispatch, rootGetters}, data) => {
            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/users/partners/update_partner_documents', 'PUT', data.body, data.query, custom_headers)

            if(response.success && response.data) {
                dispatch('showAlert', 'Info saved successfully', { root: true })
                dispatch('hiddenLoading', null, { root: true })
                commit('setError', false)
            }
            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    switch(response.errors[error].code) {
                        case 'error_empty_documents':
                            messages.push('Error: Documents required.')
                            break
                        default:
                            messages.push('An error has occurred try later')
                            break
                    }
                }
                commit('setError', true)
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', messages, { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        delete: async({commit, dispatch, rootGetters}, id) => {
            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/users/partners/delete','DELETE', null, { id_partner: id }, custom_headers)

            if(response.success && response.data) {
                dispatch('showAlert', 'Partner deleted successfully', { root: true })
                commit('setError', false)
            }
            else if(!response.success && response.errors) {
                commit('setError', true)
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', 'An error has occurred try later', { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        changePassword: async({commit, dispatch, rootGetters}, data) => {
            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('admin/users/partners/change_password','PUT', data.body, data.query, custom_headers)

            if(response.success && response.data) {
                dispatch('showAlert', 'Password changed sucessfully', { root: true })
                commit('setError', false)
            }
            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    switch(response.errors[error].code) {
                        case 'error_empty_password':
                            messages.push('Error: new password required')
                            break
                        case 'error_empty_confirm_password':
                            messages.push('Error: confirm password required')
                            break
                        case 'error_passwords_not_match':
                            messages.push('Error: passwords doesn\'t match');
                            break
                        case 'error_traveller_access_not_found':
                            messages.push('Error: Can only change password to traveller with previous access data');
                            break
                        default:
                            messages.push('An error has ocurred, wait and try later')
                            break
                    }
                }
                commit('setError', true)
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', messages, { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        }
    }
}

export default partners