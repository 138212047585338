/*type: 'dw' 'min' 'normal' */
export default function informativeDate(date, type = 'min', lang = 'es') {
	let days = {
		es: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
		en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
	}
	let months = {
		es: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
		en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
	}
	
	date = new Date(date)
	//this.$log('date: ', date)

	let date_day_week = days[lang][date.getDay()]
    //this.$log('Day Week', date_day_week)

    let date_day = date.getDate()
    date_day = (date_day < 10) ? `0${date_day}` : date_day
    //this.$log('Day', date_day)
    
    let date_month = months[lang][date.getMonth()]
    let number_month = date.getMonth() + 1
    number_month = (number_month < 10) ? `0${number_month}` : number_month
    //this.$log('Month', date_month)

    let date_age = date.getFullYear()
    //this.$log('Age', date_age)

    let finally_date = ''

    if(lang == 'es') {
    	finally_date = (type === "min") ? `${date_day}-${number_month}-${date_age}` :
    				   (type === "dw") ? `${date_day_week} ${date_day} de ${date_month} del ${date_age}` : 
    						`${date_day} de ${date_month} ${date_age}`
    }
    if(lang == 'en') {
    	finally_date = (type === "min") ? `${date_day}/${number_month}/${date_age}` :
    				   (type === "dw") ? `${date_day_week} ${date_day} of ${date_month}, ${date_age}` : 
    						`${date_day} of ${date_month} ${date_age}`
    }

	//this.$log('Finally date', finally_date)    						  

    return finally_date

}
