<template>
    <div :class="[`default-${theme}-${variant} position-relative w-100`]">

        <!--selector label-->
        <span class="selector-label text-star d-block text-dark poppins-regular text-capitalize" v-if="show_label">
            {{ label }}
        </span>

        <!-- selector option-->
        <div class="current-option position-relative" @click.stop="open"
            :class="[(rounded) ? 'rounded' : '', (bordered) ? 'bordered' : '']"
            :style="[(disabled) ? 'opacity: 0.7;' : '']">
            
            <div class="d-flex align-items-center"
                :class="[(only_icon) ? 'justify-content-evenly' : 'justify-content-between']">

                <img :src="current_option ? current_option.icon : null" v-if="img_selector && current_option"
                    class="p-1"
                    :width="img_settings.width"
                    :height="img_settings.height" />

                <span class="text-dark" v-if="!only_icon">
                    {{ current_option ? current_option.label : placeholder }}
                </span>

                <span class="text-dark" v-if="img_selector && only_icon && !current_option">
                    {{ placeholder }}
                </span>

                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"
                    class="custom_fill mt-2 me-2"
                    style="justify-self: flex-end !important;"
                    :class="[ (is_searching) ? 'active' : '']">
                    <path d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z"></path>
                </svg>

            </div>

            <input @input="search" v-show="is_searching && search_selector"
                v-model="search_query" 
                class="w-100 position-absolute" 
                style="inset: 0;"
                ref="search_input"
                type="text"
                placeholder="Buscar..."
                >

                
        </div>


        <!--Selector options -->
        <div class="options-container d-flex flex-column" v-if="is_searching"
            @mouseleave="is_searching = false">

            <div v-for="(option, index) in local_options" :key="`option-${index}`"
                class="d-flex align-items-center"
                :class="[(only_icon) ? 'justify-content-center' : 'justify-content-start']"
                @click.stop="setOption(option)">
                <img :src="option.icon" v-if="img_selector"
                    class="ms-2"
                    :width="img_settings.width"
                    :height="img_settings.height" />

                <span class="option d-block text-start text-dark" v-if="!only_icon">
                    {{ option.label }}
                </span>
            </div>

            
        </div>


        <!--hidden input works for v-model only-->
        <input :value="modelValue" class="d-none" />
    </div>
</template>

<script setup>

    import { onMounted, ref, watch } from 'vue'

    //props

    const props = defineProps({
        _id: {
            type: String,
            required: false,
            default: `selector-${Math.random() * 1000}`
        },
        modelValue: {
            type: [Number, String]
        },
        label: {
            type: String,
            required: false,
            default: 'Default Label:'
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Select'
        },
        show_label: {
            type: Boolean,
            required: false,
            default: false
        },
        options: {
            type: Array,
            required: true,
            default: () => ([{id: 1, label: 'Naruto' }, {id: 2, label: 'Fairy Tale'}, {id: 3, label: 'Devil May Cry'},{id: 4, label: 'Devil Cry Baby'},{id: 4, label: 'Devil Gray Man'},{id: 5, label: 'Code Geass'},{id: 6, label: 'Ao No Exorcist'}])
        },
        rounded: {
            type: Boolean,
            required: false,
            default: true
        },
        theme: {
            type: String,
            required: false,
            default: 'dark',
        },
        variant: {
            type: String,
            required: false,
            default: 'light'
        },
        bordered: {
            type: Boolean,
            required: false,
            default: true
        },
        search_selector: {
            type: Boolean,
            required: false,
            default: false
        },
        img_settings: {
            type: Object,
            required: false,
            default: () => ({ width: 25, height: 25})
        },
        img_selector: {
            type: Boolean,
            required: false,
            default: false
        },
        only_icon: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    });

    const emits = defineEmits(['update:modelValue', 'custom:change'])


    //variables
    const current_option = ref(null)
    const local_options = ref(null)
    const search_input = ref(null)
    const is_searching = ref(false)
    const search_query = ref(null)

    //methods

    const open = () => {
        if(props.disabled) return
        is_searching.value = !is_searching.value
        
        setTimeout(() => {
            search_input.value.focus()
        })
    }

    const search = () => {
        if(search_query.value) {
            local_options.value = local_options.value.filter( option => option.label.toLowerCase().includes(search_query.value.toLowerCase()) )
        }

        else {
            local_options.value = [...props.options]
        }
    }

    const setOption = (option) => {
        current_option.value = option
        emits('update:modelValue', option.id)
        emits('custom:change', option.id)
        search_query.value = null
        is_searching.value = false
    }

    //watchers
    watch( () => props.modelValue, (first, second) => {
        if(props.modelValue) {
            current_option.value = local_options.value.find( item => item.id == props.modelValue)
        }

        else {
            current_option.value = null
        }
    })

    watch(() => props.options, (first, second) => {
        console.log('HERE SELECTOR 1')
        local_options.value = [...props.options]
        console.log('HERE SELECTOR 2')
    })


    //Vue lifecycle
    onMounted(() => {
        local_options.value = [...props.options]
        current_option.value = local_options.value.find( item => item.id == props.modelValue)
    })
    


</script>

<style lang="scss" scoped>

    @use 'sass:color';

    $themes: 'dark','light';

    $variants : (
        "primary":    #133831,
        "secondary":  #7E7A6E,
        "success":    #1D2524,
        "info":       #D1DBC2,
        "warning":    #ffc107,
        "danger":     #dc3545,
        "light":      white,
        "dark":       #343a40
    );

    @each $theme in $themes {
        @each $variant, $color in $variants {

            .default-#{$theme}-#{$variant} {

                .selector-label {
                    @if($theme == 'dark') {
                        color: white;
                    }

                    @else {
                        color: black;
                    }
                }

                .current-option, .options-container {
                    overflow: hidden;
                    background-color: map-get($variants, 'light');
                    color: black;
                    padding: 5px 0 8px 8px;
                    height: 40px;

                    &.bordered {
                        border: 1px solid $color;
                        border-radius: 20px;
                    }
                    
                    transition: all 0.5s;
                }

                .current-option {
                    .custom_fill {
                        fill: black;
                        transition: all 0.4s ease-in-out;
                        &.active {
                            transform: rotate(-180deg);
                        }
                    }
                }

                .current-option:hover {
                        color: $color;
                    }

                .options-container {
                    width: 100%;
                    height: 100px;
                    overflow-y: scroll;
                    scrollbar-width: thin;
                    border: 1px solid $color;
                    position: absolute;
                    border-top: none;
                    padding: 0px;
                    box-shadow: none;
                    z-index: 10000;
                    

                    @if($theme == 'dark') {
                        scrollbar-color: $color color.adjust($color, $lightness: -10%);
                        &::-webkit-scrollbar {
                            width: 5px;
                        }

                        &::-webkit-scrollbar-track {
                            background-color: color.adjust($color, $lightness: -10%);
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: $color;
                        }
                    }

                    @if($theme == 'light') {
                        scrollbar-color: $color color.adjust($color, $lightness: 12%);

                        &::-webkit-scrollbar {
                            width: 5px;
                        }

                        &::-webkit-scrollbar-track {
                            background: color.adjust($color, $lightness: 12%);
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: $color;
                        }
                    }
                    

                    .option {
                        width: 100%;
                        padding-left: 5px;
                        transition: all 0.5s;
                        &:hover {
                            outline: 1px solid $color;
                        }
                    }
                }
            }

        }
    }
    

</style>
