import sendRequest from "@/libs/sendRequest";
import redirect from "@/libs/redirect";

const login = {
    namespaced: true,
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        // Login
        login: async({commit, dispatch}, payload) => {
            dispatch('showLoading', null, { root: true })
            const response = await sendRequest('admin/auth/login', 'POST', payload)

            if(response.success && response.data) {
                if(!response.data.hasOwnProperty('session') || !response.data.hasOwnProperty('user')) {
                    dispatch('showError', 'An error has ocurred, try later again', { root: true })
                    return;
                }

                commit('setSession', response.data, { root: true })


                redirect(response.data.user.rol_name == 'administrator' ? 'countries' : 'experiences');
            }
            else if(!response.success && response.errors) {
                let messages = new Array()
                for(let error of response.errors) {
                    if(error.code === 'error_empty_username') {
                        messages.push('Please add your username')
                    }
                    else if(error.code === 'error_empty_password') {
                        messages.push('Please add your password')
                    }
                    else {
                        messages.push('An error has ocurred, please try later.')
                    }
                }
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', messages, { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        }
    }
}

export default login;