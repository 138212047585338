<template>
    <div class="button_wrapper position-relative">
        <div class="button_item" v-for="(button, index) in buttons " :key="index">
            <button @click="btnClick(button.id)"
                    class="btn"
                    :class="[(step === button.id) ? 'btn-primary' : 'btn-secondary border border-info']"
                    disabled
                    type="button">
                <span :class="[(step === button.id) ? 'text-white' : 'text-info']"
                    class="poppins-regular">
                    {{ button.label }}
                </span>
            </button>
            <div class="m-0 mt-3 " :class="[(step === button.id ? 'border-3 border-light border rounded' : '')]"></div>
        </div>
        <hr class="position-absolute w-100 border-light border-1" style="bottom: -6.8%;">
    </div>
</template>

<script setup>
    import {ref, watch} from "vue";

    const props = defineProps({
        buttons: {
            type: Array,
            required: false,
            default: () => ([{id:1, label: 'Button 1'},{id:2, label: 'Button 2'},{id:3, label: 'Button 3'}])
        },
        step: {
            type: String,
            required: false,
            default: '',
        }
    })


    const emits = defineEmits(['btn:handler']);

    const btnClick = (option_id) => {
        emits('btn:handler', option_id);
    }

</script>

<style lang="scss" scoped>
    .button_wrapper{
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: center;

        .button_item{
            flex-grow: 1;
            padding: 10px;
            display: flex;
            flex-direction: column;
            height: 80px;
            button {
                width: 100%;
            }
        }
    }
</style>