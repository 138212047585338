<template>
    <div id="template-dashboard" class="flex-center">
        <!-- Sidebar -->
        <fa-sidebar v-if="show_menu"></fa-sidebar>

        <section class="td-container bg-primary">

                <div class="bg-light p-3 rounded-20">
                    <!-- Header -->
                    <fa-header @hide="show_menu = !show_menu">
                    </fa-header>

                    <!-- Content View -->
                    <div class="td-subcontainer"
                         style="min-height: calc(100vh - 153px);">
                        <slot></slot>
                    </div>
                </div>



        </section>
	</div>
</template>

<script setup>
    // Imports
    import { ref, computed } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // Images

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Injects

    // Computeds

    // Variables
    const show_menu = ref(true);

    // Methods

    // Vue LifeCycle

</script>


<style lang="scss" scoped>
    @import 'ASSETS/scss/minxin.scss';

    #template-dashboard {
        height: 100vh;

        .td-container {
            padding: .75rem .75rem .75rem 0;
            width: 100%;
            height: 100vh;
            overflow-y: scroll;
            


            .td-subcontainer {
                background-color: var(--bs-white);
                border-radius: 24px;
                height: 100%;
                width: 100%;
            }
        }
    }
</style>