import sendRequest from "@/libs/sendRequest"
import { customRef } from "vue";

const support_chat = {
    namespaced: true,
    state: {
        conversation: [],
        chats: [],
        current_id: null,
        total_pages: null,
        conversation_last_page: null,
        chats_last_page: null
    },
    getters: {
        getConversation: (state) => (state.conversation),
        getChats: (state) => (state.chats),
        getTotalPages: (state) => (state.total_pages),
        getConversationLastPage: (state) => (state.conversation_last_page),
        getChatsLastPage: (state) => (state.chats_last_pages),
        getCurrentId: (state) => (state.current_id)
    },
    mutations: {
        setConversation: (state, payload) => state.conversation = payload,
        setChats: (state, payload) => state.chats = payload,
        setTotalPages: (state, payload) => state.total_pages = payload,
        setConversationLastPage: (state, payload) => state.conversation_last_page = payload,
        setChatsLastPage: (state, payload) => state.chats_last_page = payload,
        setCurrentId: (state, payload) => state.current_id = payload
    },
    actions: {
        listTravellerConversation: async ({ commit, dispatch, rootGetters, getters }, query) => {

            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/support/traveller/conversation', 'GET', null, query, custom_headers)

            if (response.success && response.data) {
                if (query.search) {
                    commit('setConversation', response.data.list.result ?? [])
                }
                else if (query.datatable) {

                    let current_messages = [...getters.getConversation]
                    const new_message = response.data.list.result ?? []

                    new_message.forEach((message) => {
                        let index = current_messages.findIndex((c_message) => c_message.id_support_traveller === message.id_support_traveller)
                        // console.log(message.id_support_traveller)
                        if (index === -1) {
                            current_messages.unshift(message);
                        }
                    })

                    current_messages = current_messages.sort((a, b) => {
                        return a.id_support_traveller - b.id_support_traveller
                    })

                    commit('setConversation', current_messages)
                    commit('setTotalPages', response.data.list.total_pages)

                    if (response.data.list.total_pages == query.page) {
                        commit('setConversationLastPage', true)
                    }
                    else {
                        commit('setConversationLastPage', false)
                    }
                }
                else {
                    commit('setConversation', response.data.list)
                }

            }
            else if (!response.success && response.errors) {
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', 'An error has occurred try later', { root: true })
            }
            else if (!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        listTravellerChats: async ({ commit, dispatch, rootGetters, getters }, query) => {

            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/support/traveller/messages', 'GET', null, query, custom_headers)

            if (response.success && response.data) {
                if (query.search) {
                    commit('setChats', response.data.list.result ?? [])
                }
                else if (query.datatable) {

                    let current_chats = [...getters.getChats]
                    const new_chats = response.data.list.result ?? []

                    new_chats.forEach((chat) => {
                        let index = current_chats.findIndex((c_chat) => c_chat.id_traveller === chat.id_traveller)

                        if (index === -1) {
                            current_chats.unshift(chat);
                        }
                    })

                    current_chats = current_chats.sort((a, b) => {
                        return a.id_traveller - b.id_traveller
                    })

                    commit('setChats', current_chats)
                    commit('setTotalPages', response.data.list.total_pages)

                    if (response.data.list.total_pages == query.page) {
                        commit('setChatsLastPage', true)
                    }
                    else {
                        commit('setChatsLastPage', false)
                    }
                }
                else {
                    commit('setChats', response.data.list ?? [])
                }

            }
            else if (!response.success && response.errors) {
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', 'An error has occurred try later', { root: true })
            }
            else if (!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        sendTravellerMessage: async ({ commit, dispatch, rootGetters }, data) => {

            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/support/traveller/send', 'POST', data.body, data.query, custom_headers)

            if (response.success && response.data) {
                console.log('success response:', response)
            }
            else if (!response.success && response.errors) {
                dispatch('hiddenLoading', null, { root: true })
            }
            else if (!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        listLocalGuideConversation: async ({ commit, dispatch, rootGetters, getters }, query) => {

            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/support/localguide/conversation', 'GET', null, query, custom_headers)

            if (response.success && response.data) {
                if (query.search) {
                    commit('setConversation', response.data.list.result ?? [])
                }
                else if (query.datatable) {

                    let current_messages = [...getters.getConversation]
                    const new_message = response.data.list.result ?? []

                    new_message.forEach((message) => {
                        let index = current_messages.findIndex((c_message) => c_message.id_support_local_guide === message.id_support_local_guide)
                        if (index === -1) {
                            current_messages.unshift(message);
                        }
                    })

                    current_messages = current_messages.sort((a, b) => {
                        return a.id_support_local_guide - b.id_support_local_guide
                    })
                    commit('setConversation', current_messages)
                    commit('setTotalPages', response.data.list.total_pages)

                    if (response.data.list.total_pages == query.page) {
                        commit('setConversationLastPage', true)
                    }
                    else {
                        commit('setConversationLastPage', false)
                    }
                }
                else {
                    commit('setConversation', response.data.list)
                }

            }
            else if (!response.success && response.errors) {
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', 'An error has occurred try later', { root: true })
            }
            else if (!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        listLocalGuideChats: async ({ commit, dispatch, rootGetters }, query) => {

            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/support/localguide/messages', 'GET', null, query, custom_headers)

            if (response.success && response.data) {
                if (query.search) {
                    commit('setChats', response.data.list.result ?? [])
                }
                else if (query.datatable) {
                    let current_chats = [...getters.getChats]
                    const new_chats = response.data.list.result ?? []

                    new_chats.forEach((chat) => {
                        let index = current_chats.findIndex((c_chat) => c_chat.id_local_guide === chat.id_local_guide)

                        if (index === -1) {
                            current_chats.unshift(chat);
                        }
                    })

                    current_chats = current_chats.sort((a, b) => {
                        return a.id_local_guide - b.id_local_guide
                    })

                    commit('setChats', current_chats)
                    commit('setTotalPages', response.data.list.total_pages)

                    if (response.data.list.total_pages == query.page) {
                        commit('setChatsLastPage', true)
                    }
                    else {
                        commit('setChatsLastPage', false)
                    }
                }
                else {
                    commit('setChats', response.data.list)
                }
                dispatch('hiddenLoading', null, { root: true })
            }
            else if (!response.success && response.errors) {
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', 'An error has occurred try later', { root: true })
            }
            else if (!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        sendLocalGuideMessage: async ({ commit, dispatch, rootGetters }, data) => {

            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/support/localguide/send', 'POST', data.body, data.query, custom_headers)

            if (response.success && response.data) {
                console.log('success response:', response)
            }
            else if (!response.success && response.errors) {
                dispatch('hiddenLoading', null, { root: true })
            }
            else if (!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        }
    }
}

export default support_chat;