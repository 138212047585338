export default [
    {
        path: '/reports/user-admin',
        name: 'report-log-admin',
        meta: {
            layout: 'dashboard',
        },
        component: () => import('VIEWS/Reports/LogUserAdmin.vue')
    },
    {
        path: '/reports/user-partner',
        name: 'report-log-partner',
        meta: {
            layout: 'dashboard',
        },
        component: () => import('VIEWS/Reports/LogPartner.vue')
    },
    {
        path: '/reports/user-traveller',
        name: 'report-log-traveller',
        meta: {
            layout: 'dashboard',
        },
        component: () => import('VIEWS/Reports/LogTraveller.vue')
    },
    {
        path: '/reports/user-local-guide',
        name: 'report-log-local-guide',
        meta: {
            layout: 'dashboard',
        },
        component: () => import('VIEWS/Reports/LogLocalGuide.vue')
    },
    {
        path: '/reports/experiences',
        name: 'report-experiences',
        meta: {
            layout: 'dashboard',
        },
        component: () => import('VIEWS/Reports/Experiences.vue')
    },
    {
        path: '/reports/reviews',
        name: 'report-reviews',
        meta: {
            layout: 'dashboard',
        },
        component: () => import('VIEWS/Reports/Reviews.vue')
    },
    {
        path: '/reports/catalogs',
        name: 'report-catalogs',
        meta: {
            layout: 'dashboard',
        },
        component: () => import('VIEWS/Reports/Catalogs.vue')
    }
];