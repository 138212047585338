<template>
    <div class="dropdown-container"
        :class="[`default-${theme}-${variant}`,(bordered) ? 'bordered' : '',(rounded) ? 'rounded' : '' ]">

        <div @click.stop="open = !open"
            class="d-flex justify-content-between">
            <div></div>

            <span class="dropdown-label ps-4" :style="`height: ${height}px;`">
                {{ label }}
            </span>

            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"
                class="custom_fill mt-2 pt-1 me-2"
                style="justify-self: flex-end !important;"
                :class="[ (open) ? 'active' : '']">
                    <path d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z"></path>
            </svg>
        </div>
        

        <div class="button-container"
            :class="(open) ? 'open' : ''">
            <button v-for="(button, index) in buttons" :key="`drop-btn-${index}`"
                @click.stop="sendBtn(button)"
                class="drop-btn"
                type="button">
                {{ button.label}}
            </button>
        </div>
    </div>
</template>

<script setup>

    import { ref } from 'vue'

    const props = defineProps({
        _id: {
            type: String,
            required: false,
            default: `dropdown-${Math.random() * 100}`
        },
        theme: {
            type: String,
            required: false,
            default: 'default'
        },
        variant: {
            type: String,
            required: false,
            default: 'success'
        },
        label: {
            type: String,
            required: false,
            default: 'Dropdown'
        },
        bordered: {
            type: Boolean,
            required: false,
            default: false
        },
        rounded: {
            type: Boolean,
            required: false,
            default: true
        },
        height: {
            type: String,
            required: false,
            default: '40'
        },
        buttons: {
            type: Array,
            required: false,
            default: () => ([{id: 1, label: 'Button 1'},{id: 2, label: 'Button 2'},{id: 3, label: 'Button 3'}])
        }
    })

    //variables
    const open = ref(false)

    //emits
    const emits = defineEmits(['btn:handler'])

    const sendBtn = (button) => {
        emits('btn:handler', button.id)
        open.value = false
    }

</script>

<style lang="scss">
    @use 'sass:color';

    $themes: 'dark','light', 'default';

    $variants : (
        "primary":    #0d6efd,
        "secondary":  #6f42c1,
        "success":    #2B675A,
        "info":       #0dcaf0,
        "warning":    #ffc107,
        "danger":     #dc3545,
        "light":      #f8f9fa,
        "dark":       #343a40
    );

    @each $theme in $themes {
        @each $variant, $color in $variants {

            .default-#{$theme}-#{$variant}.dropdown-container {
                display: flex;
                flex-direction: column;
                @if($theme == 'dark') {
                    background-color: map-get($variants , 'dark' );
                }
                @else if($theme == 'light') {
                    background-color: map-get($variants , 'light' );
                }
                @else {
                    background-color: $color;
                }
                color: color.adjust($color, $lightness: -5%);
                @if($theme == 'default') {
                    color: white;
                }

                &.bordered {
                    outline: 1px solid $color;
                }

                .dropdown-label {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: all 0.5s;
                    cursor: pointer;

                    &:hover {
                        @if($theme == 'light') {
                            color: color.adjust($color, $lightness: 5deg);
                        }

                        @else if($theme == 'dark') {
                            color: color.adjust($color, $lightness: 5%);
                        }

                        @else {
                            color: white;
                        }
                        
                    }
                }

                .custom_fill {
                    fill: $color;
                    transition: all 0.5s ease-in-out;
                    @if($theme == 'default') {
                        fill: white;
                    }

                    &.active {
                        transform: rotate(-180deg);
                    }
                }

                .button-container {
                    display: flex;
                    flex-direction: column;
                    max-height: 0px;
                    overflow: hidden;
                    transition: all 0.4s ease-in-out;

                    &.open {
                        max-height: 200px;
                    }

                    .drop-btn {
                        border: 1px solid transparent;
                        background: $color;
                        color: white;
                        padding: 5px 0px;
                        margin: 5px 0;;
                        transition: all 0.4s ease-in-out;
                        font-weight: bold;

                        &:first-child, &:last-child {
                            margin: 0;
                        }

                        &:hover {
                            @if($theme == 'default') {
                                border: 1px solid white;
                            }
                            @else {
                                border: 1px solid $color;
                            }
                            
                            @if($theme == 'light') {
                                color: color.adjust($color, $lightness: 5deg);
                            }

                            @else if($theme == 'dark') {
                                color: color.adjust($color, $lightness: 5%);
                            }

                            @else {
                                color: white;
                            }
                            
                        }
                        
                    }
                }
                
            }

        }
    }

</style>
