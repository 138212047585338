import sendRequest from "@/libs/sendRequest"

const roles = {
    namespaced: true,
    state: {
        rol: {},
        roles: [],
        error: false,
        last_page: null,
        total_pages: null
    },
    getters: {
        getRol: (state) => (state.rol),
        getRoles: (state) => (state.roles),
        getLastPage: (state) => (state.last_page),
        getTotalPages: (state) => (state.total_pages),
        getError: (state) => (state.error)
    },
    mutations: {
        setRol: (state, payload) => state.rol = payload,
        setRoles: (state, payload) => state.roles = payload,
        setLastPage: (state, payload) => state.last_page = payload,
        setTotalPages: (state, payload) => state.total_pages = payload,
        setError: (state, payload) => state.error = payload,
    },
    actions: {
        list: async({commit, dispatch, rootGetters}, query) => {
            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/catalogs/roles/list', 'GET', null, query, custom_headers)

            if(response.success && response.data) {
                if(query.datatable) {
                    commit('setRoles', response.data.list.result)
                    commit('setTotalPages', response.data.list.total_pages)

                    if(response.data.list.total_pages == query.page) {
                        commit('setLastPage', true)
                    }
                    else {
                        commit('setLastPage', false)
                    }
                }
                else {
                    commit('setRoles', response.data.list)
                }
                dispatch('hiddenLoading', null, { root: true })
            }
            else if(!response.success && response.errors) {
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', 'An error has occurred try later', { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        view: async({commit, dispatch, rootGetters}, query) => {
            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/catalogs/roles/view', 'GET', null, query, custom_headers)

            if(response.success && response.data) {
                commit('setRol', response.data.view)
                dispatch('hiddenLoading', null, { root: true })
            }
            else if(!response.success && response.errors) {
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', 'An error has occurred try later', { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        }
    }
}

export default roles