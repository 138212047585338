<template>
    <fa-loading></fa-loading>
    <fa-alert></fa-alert>

    <component :is="layout || 'section'">
        <router-view></router-view>
    </component>
    
</template>

<script setup>
    // Imports
    import { ref, computed } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Injects

    // Computeds
    const layout = computed(() => {
        return router.currentRoute.value.meta.layout
    })

    // Variables

    // Methods

    // Vue LifeCycle
    
</script>

<style lang="scss">
    @use "sass:color";
    
    * {
	    scrollbar-color: #f3f6f5 #DBE3E205;
	    scrollbar-width: thin;
    }

    .dp__input_wrap {
	    border: none;
		outline: none;
		border-radius: 50%;

	    .dp__input {
		    border-radius: 20px;
	    }

    }

    #app {
        font-family: 'Playfair Display';
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: left;
        color: #2C3E50;
    }
    
    // Variable overrides first
    //$primary: #133831;      // #0D6EFD;
    //$secondary: #7E7A6E;    // #6C757D;
    //$success: #1D2524;      // #198754;
    //$light: #F8F9FA;        // #F8F9FA;

    $primary: #133831;
    $secondary: #7C9895;
    $success: #DBE3E2;
    $info: #D1DBC2;         // #0DCAF0;
    $warning: #FFC107;      // #FFC107;
    $danger: #DC3545;       // #DC3545;
    $light: #F3F6F5;
    $dark: #212529;         // #212529;

    // Focus Form control
    $focus-ring-color: color.adjust($primary, $lightness: 75%, $alpha: -0.5); //#bce2b8;
    $component-active-bg: color.adjust($primary, $lightness: 25%, $alpha: -0.5);
    

    // Then import Bootstrap
    @import "../node_modules/bootstrap/scss/bootstrap";
</style>