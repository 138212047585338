import sendRequest from "@/libs/sendRequest"

const reservations = {
    namespaced: true,
    state: {
        reservation: {},
        reservations: [],
        error: false,
        last_page: null,
        total_pages: null
    },
    getters: {
        getReservation: (state) => (state.reservation),
        getReservations: (state) => (state.reservations),
        getLastPage: (state) => (state.last_page),
        getTotalPages: (state) => (state.total_pages),
        getError: (state) => (state.error)
    },
    mutations: {
        setReservation: (state, payload) => state.reservation = payload,
        setReservations: (state, payload) => state.reservations = payload,
        setLastPage: (state, payload) => state.last_page = payload,
        setTotalPages: (state, payload) => state.total_pages = payload,
        setError: (state, payload) => state.error = payload,
    },
    actions: {
        list: async({commit, dispatch, rootGetters}, query) => {

            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('admin/reservations/list', 'GET', null, query, custom_headers)

            if(response.success && response.data) {
                if(query.datatable) {
                    commit('setReservations', response.data.list.result)
                    commit('setTotalPages', response.data.list.total_pages)

                    if(response.data.list.total_pages == query.page) {
                        commit('setLastPage', true)
                    }
                    else {
                        commit('setLastPage', false)
                    }
                }
                else {
                    commit('setReservations', response.data.list ?? []);
                }
                dispatch('hiddenLoading', null, { root: true })
            }
            else if(!response.success && response.errors) {
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', 'An error has occurred try later', { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        view: async({commit, dispatch, rootGetters}, query) => {

            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('admin/reservations/view', 'GET', null, query, custom_headers)

            if(response.success && response.data) {

                commit('setReservation', response.data.view ?? {})

                dispatch('hiddenLoading', null, { root: true })
            }
            else if(!response.success && response.errors) {
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', 'An error has occurred try later', { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        update: async({commit, dispatch, rootGetters}, data) => {

            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('admin/reservations/update', 'PUT', data.body, data.query, custom_headers)

            if(response.success && response.data) {

                dispatch('showAlert', 'Reservation has been updated successfully', { root: true })
                commit('setError', false)
                dispatch('hiddenLoading', null, { root: true })
            }
            else if(!response.success && response.errors) {
                let messages = []

                for(let error in response.errors) {
                    switch(response.errors[error].code) {
                        case 'error_empty_name':
                            messages.push('Error: Please add a name.')
                            break
                        case 'error_bank_inactive':
                            messages.push('Error: Bank is inactive')
                            break
                        case 'error_bank_has_already_exist':
                            messages.push('Error: The banks already exists.')
                            break
                        default:
                            messages.push('An error has occurred try later')
                            break
                    }
                }
                commit('setError', true)
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', messages, { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        cancel: async({commit, dispatch, rootGetters}, query) => {

            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('admin/reservations/cancel', 'PUT', null, query, custom_headers)

            if(response.success && response.data) {

                dispatch('showAlert', 'Reservation has been canceled successfully', { root: true })
                commit('setError', false)
                dispatch('hiddenLoading', null, { root: true })
            }
            else if(!response.success && response.errors) {
                let messages = []

                for(let error in response.errors) {
                    switch(response.errors[error].code) {
                        case 'error_reservation_not_found':
                            messages.push('Error: Reservation not found.')
                            break
                        case 'error_bank_inactive':
                            messages.push('Error: Bank is inactive')
                            break
                        case 'error_reservation_is_declined':
                            messages.push('Error: The reservation is declined.')
                            break
                        case 'error_reservation_is_cancel':
                            messages.push('Error: The reservation is canceled.')
                            break
                        default:
                            messages.push('An error has occurred try later')
                            break
                    }
                }
                commit('setError', true)
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', messages, { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },

    }
}

export default reservations