<template>
    <div id="template-login" class="p-0">
        <slot></slot>
	</div>
</template>

<script setup>
    // Imports
    import { ref, computed } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"

    // Images

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Injects

    // Computeds

    // Variables

    // Methods

    // Vue LifeCycle

</script>


<style lang="scss" scoped>
    @import 'ASSETS/scss/minxin.scss';

    #template-login {
        overflow: hidden;
        height: 100vh;
        width: 100vw;
    }
</style>