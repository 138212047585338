<template>
    <div class="recovery__container flex-center mb-4">

        <template v-for="(token, i_token) in code_length" :key="`token${i_token}`">
            <input :id="`input_token${i_token + 1 }`" v-model="tokens[`token${i_token + 1 }`]" 
                @input="inputToken(`token${i_token + 1 }`, $event)"
                class="form-control recovery__container--input description-md m-1 m-sm-2"/>
        </template>|

    </div>
</template>

<script setup> 
// Imports
    import { ref, reactive, computed, onMounted } from 'vue'
    import { useStore } from "vuex"

    // Images

    // Hooks
    const store = useStore()

    // Props
    const props = defineProps({
        modelValue: {
            required: true,
            type: [null, Boolean, String],
            default: () => {
                return false
            }
        },
        code_length: {
            type: Number,
            required: false,
            default: 6,
            validator: function (value) {
                return value >= 4 && value <= 12 
            }
        },
        final_element_focus: {
            type: String,
            required: true,
            default: null
        }
    })

    // Emits
    const emit = defineEmits(['update:modelValue'])

    // Injects

    // Computeds
    const final_value = computed(() => {
        let value = ''
        for(let i in tokens) {
            let token = tokens[i]
            value += token
        }
        return value
    })

    // Variables
    const tokens = reactive(new Object())
    for(let i in props.code_length) {
        tokens[`token${i}`] = ''
    }

    // Methods
    const inputToken = (i_token, event) => {    

        // Verify if event data is number
        let is_number = /\d/.test(event.data)
        
        if(is_number) {
            tokens[i_token] = event.data

            let number_token = Number(i_token.substring(5, 6))
            if(number_token < props.code_length) {
                let a = document.getElementById(`input_token${number_token + 1}`).focus()
            }
            else {
                document.getElementById(props.final_element_focus).focus()
            }
        }
        else {
            tokens[i_token] = tokens[i_token].replace(/[^\d]/, '')
        }
        
        emit('update:modelValue', final_value.value)
    }

</script>

<style lang="scss" scoped>
    .recovery {

        &__container {

            &--input {
                padding: 8px 0px 12px 0px;
                max-width: clamp(40px, 64px, 64px);
                max-height: clamp(40px, 82px, 82px) !important;
                height: 100% !important;
                text-align: center;
                border-radius: 8px;
            }
            
        }
    }
</style>