import _Router from '@/router'

export default async function sendRequest(url = null, method = 'GET', body = null, query = null, custom_headers = null) {
    let send_url = `${CONFIG.server[CONFIG.env].api}/${url}`
    let headers = (custom_headers == null) ? CONFIG.server[CONFIG.env].headers : custom_headers

    let send_data = {
        method: method,
        headers: headers
    }

    // QUERY
    if(query !== null) {
        let formQuery = []
        for(var property in query) {
            var encodedKey = encodeURIComponent(property)
            var encodedValue = encodeURIComponent(query[property])
            formQuery.push(encodedKey + "=" + encodedValue)
        }
        formQuery = formQuery.join("&")

        if(method == 'GET') {
            send_url = `${send_url}?${formQuery}`
        }
        else {
            send_url = `${send_url}?${formQuery}`
        }
    }
    // BODY
    if(body !== null) {
        send_data.body = JSON.stringify(body)
    }

    // CONSOLES LOG
    // console.log('SEND URL:', send_url)
    // console.log('SEND DATA:', send_data)

    // FETCH
    let resp = fetch(send_url, send_data).then(res => res.json())
    .catch(async error => {
        // console.log('ERROR:', error)
    })
    .then(async response => {
        //console.log('RESPONSE:', (response !== null) ? response : 'Response is null')

        if(response && response.http == 200 && response.data !== null) {
            // console.log('DATA:', response.data)

            return {
                data: response.data,
                success: true
            }
        }
        else if(response && response.http == 400) {
            // console.log('ERRORS:', response.errors)

            return {
                errors: response.errors,
                success: false
            }
        }
        else if(response && response.http == 202) {
            // console.log('ACCEPTED:', response.errors)

            return {
                errors: response.errors,
                success: false
            }
        }
        else if(response && response.http == 404) {
            // console.log('NOT FOUND:', response.errors)

            return {
                error: [{
                    code: 404,
                    message: 'Url not found'
                }],
                success: false
            }
        }
        if(response && response.http == 401 && response.errors !== null) {
            sessionStorage.clear()
            _Router.push({ name: 'login' })

            return {
                data: [],
                success: true
            }
        }
        else {
            return {
                success: false,
                data: null
            }
        }

    })

    return resp
}