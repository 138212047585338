<template>
    <div class="floatmenu-container" @mouseleave="show_menu = false">
        <button @mouseenter="toggleMenu"
                @click.stop="toggleMenu"
            class="open-btn description-xxs text-capitalize"
            type="button">
            <span>
                Actions
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;"><path d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z"></path></svg>
        </button>

        <ul  @mouseleave="toggleMenu" v-if="show_menu"
            class="btn-container border" >
            <li v-for="(btn, index) in btn_list" :key="`menu-option-${index}`">
                <button @click.stop="sendAction(btn.id)"
                    class="btn-item description-xxs"
                    type="button">
                    {{ btn.label }}
                </button>
            </li>
        </ul>
    </div>
</template>

<script setup>
    import { ref } from 'vue';

    //props
    const props = defineProps({
        label: {
            type: String,
            required: false,
            default: 'Acciones'
        },
        btn_list: {
            type: Array,
            required: false,
            default: () =>([
                    {id: 1, label: 'button 1'},
                    {id: 2, label: 'button 2'},
                    {id: 3, label: 'button 3'}
                ])
        },
        variant: {
            type: String,
            required: false, 
            default: 'primary'
        }
    })
    //emits
    const emits = defineEmits(['btn:handler'])


    // Hooks

    // Injects

    // Computeds

    // Variables
    const show_menu = ref(false);

    // Methods
    const toggleMenu = () => {
        console.log(show_menu.value)
        show_menu.value = !show_menu.value;
    }

    const sendAction = (id) => {
        console.log('float:', id)
        emits('btn:handler', id )
        show_menu.value = !show_menu.value
    }

</script>

<style lang="scss" scoped>

    .floatmenu-container {
        position: relative;

        .open-btn {
            height: 30px;
            border: none;
            background-color: var(--bs-light);
            border-radius: 10px;
            padding: 5px 10px;
        }

        .btn-container {
            list-style: none;
            margin: 0;
            padding: 0;
            width: 150px;
            position: absolute;
            z-index: 9;
            left: calc(50% - 75px);
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
            background-color: white;
            border-radius: 10px;

            li {

                .btn-item {
                    width: 100%;
                    border: none;
                    color: black;
                    font-weight: bold;
                    background-color: white;
                    padding: 3px 0px;

                    &:hover {
                        background-color: var(--bs-primary);
                        color: white !important;
                    }
                }
            }

            li:first-of-type .btn-item{
                margin: 0;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }

            li:last-of-type .btn-item{
                margin: 0;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
            
        }

    }

</style>
