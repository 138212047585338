<template>
    <section class="cbox_container">
        <label :class="`checkbox text-${color_text}`">
            <input :id="_id"
                   @click.stop="emits('update:modelValue', $event.target.checked)"
                   :checked="props.modelValue"
                   type="checkbox">

            <span :class="[`checkmark checkmark-${variant}`, (fill) ? `checkmark-fill-${variant}` : ``]"></span>

            <span class="checkbox__label poppins-regular text-dark">{{ label }}</span>
        </label>
    </section>
</template>

<script setup>
    const props = defineProps({
        _id: {
            type: String,
            description: "id of checkbox",
            required: false,
            default: 'cbox-1'
        },
        value: {
            type: Boolean,
            description: "defines if input is checked",
            required: false,
            default: () => {
                return false
            }
        },
        label: {
            type: String,
            description: "label for the checkbox",
            required: false,
            default: ''
        },
        variant: {
            type: String,
            description: "defines the color of the checkbox",
            required: false,
            default: 'success',

        },
        color_text: {
            type: String,
            description: "defines the text color of the chip",
            required: false,
            default: "dark",
            validator: function (value) {
                let allow_colors = ['white', 'dark']
                return allow_colors.includes(value)
            }
        },
        fill: {
            type: Boolean,
            description: "defines whether the checkbox will be normal or fill style",
            required: false,
            default: false
        },
        modelValue: {
            type: Boolean,
        }
    });

    const emits = defineEmits(["update:modelValue"]);

</script>

<style lang="scss" scoped>


.cbox_container {

    /* Hide the browser's default checkbox */
    .checkbox input {
        position: absolute;
        cursor: pointer;
        opacity: 0;
        height: 0;
        width: 0;
    }

    .checkbox {
        position: relative;
        margin-bottom: 0px;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        border-radius: 8px;
        margin-right: 12px;

        -webkit-transition: all .5s !important;
        -moz-transition: all .5s !important;
        -ms-transition: all .5s !important;
        -o-transition: all .5s !important;
        transition: all .5s !important;
    }

    .checkmark-primary {
        border: 1px solid var(--bs-primary) !important;
    }
    .checkmark-secondary {
        border: 1px solid var(--bs-secondary) !important;
    }
    .checkmark-success {
        border: 1px solid var(--bs-success) !important;
    }
    .checkmark-warning {
        border: 1px solid var(--bs-warning) !important;
    }
    .checkmark-danger {
        border: 1px solid var(--bs-danger) !important;
    }
    .checkmark-info {
        border: 1px solid var(--bs-info) !important;
    }
    .checkmark-light {
        border: 1px solid var(--bs-light) !important;
    }
    .checkmark-dark {
        border: 1px solid var(--bs-dark) !important;
    }

    /* HOVERS */
    .checkbox:hover input ~ .checkmark-primary {
        //background-color: $success;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 10px var(--bs-primary) !important;
    }
    .checkbox:hover input ~ .checkmark-secondary {
        //background-color: $success;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 10px var(--bs-secondary) !important;
    }
    .checkbox:hover input ~ .checkmark-success {
        //background-color: $success;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 10px var(--bs-success) !important;
    }
    .checkbox:hover input ~ .checkmark-warning {
        //background-color: $success;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 10px var(--bs-warning) !important;
    }
    .checkbox:hover input ~ .checkmark-danger {
        //background-color: $success;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 10px var(--bs-danger) !important;
    }
    .checkbox:hover input ~ .checkmark-info {
        //background-color: $success;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 10px var(--bs-info) !important;
    }
    .checkbox:hover input ~ .checkmark-light {
        //background-color: $success;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 10px var(--bs-light) !important;
    }
    .checkbox:hover input ~ .checkmark-dark {
        //background-color: $success;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 10px var(--bs-dark) !important;
    }


    /* CHECKED FILL */
    .checkbox input:checked ~ .checkmark-fill-primary {
        background-color: var(--bs-primary);
    }
    .checkbox input:checked ~ .checkmark-fill-secondary {
        background-color: var(--bs-secondary);
    }
    .checkbox input:checked ~ .checkmark-fill-success {
        background-color: var(--bs-success);
    }
    .checkbox input:checked ~ .checkmark-fill-warning {
        background-color: var(--bs-warning);
    }
    .checkbox input:checked ~ .checkmark-fill-danger {
        background-color: var(--bs-danger);
    }
    .checkbox input:checked ~ .checkmark-fill-info {
        background-color: var(--bs-info);
    }
    .checkbox input:checked ~ .checkmark-fill-light {
        background-color: var(--bs-light);
    }
    .checkbox input:checked ~ .checkmark-fill-dark {
        background-color: var(--bs-dark);
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    /* Show the checkmark when checked */
    .checkbox input:checked ~ .checkmark:after {
        display: block;
    }
    /* Style the checkmark/indicator */
    .checkbox .checkmark:after {
        top: 6px;
        left: 9px;
        width: 5px;
        height: 10px;

        // border: solid $light;
        // border-width: 0 2px 2px 0 !important;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .checkbox .checkmark-primary:after {
        border: solid var(--bs-primary)!important;
        border-width: 0 2px 2px 0 !important;
    }
    .checkbox .checkmark-secondary:after {
        border: solid var(--bs-secondary) !important;
        border-width: 0 2px 2px 0 !important;
    }
    .checkbox .checkmark-success:after {
        border: solid var(--bs-success) !important;
        border-width: 0 2px 2px 0 !important;
    }
    .checkbox .checkmark-warning:after {
        border: solid var(--bs-warning) !important;
        border-width: 0 2px 2px 0 !important;
    }
    .checkbox .checkmark-danger:after {
        border: solid var(--bs-danger) !important;
        border-width: 0 2px 2px 0 !important;
    }
    .checkbox .checkmark-info:after {
        border: solid var(--bs-info) !important;
        border-width: 0 2px 2px 0 !important;
    }
    .checkbox .checkmark-light:after {
        border: solid var(--bs-light) !important;
        border-width: 0 2px 2px 0 !important;
    }
    .checkbox .checkmark-dark:after {
        border: solid var(--bs-dark) !important;
        border-width: 0 2px 2px 0 !important;
    }

    .checkbox .checkmark-fill-primary:after, .checkbox .checkmark-fill-secondary:after, .checkbox .checkmark-fill-success:after,
    .checkbox .checkmark-fill-warning:after, .checkbox .checkmark-fill-danger:after, .checkbox .checkmark-fill-info:after,
    .checkbox .checkmark-fill-dark:after {
        border: solid var(--bs-light) !important;
        border-width: 0 2px 2px 0 !important;
    }

    .checkbox .checkmark-fill-light:after {
        border: solid var(--bs-dark) !important;
        border-width: 0 2px 2px 0 !important;
    }

    .checkbox__label {
        margin-left: 30px;
        color: white;
    }
}
</style>