<template>
    <header id="Header" class="flex-between-center bg-light mb-3 px-4">

        <div class="flex-center">
            <button @click.stop="hideMenu"
                class="btn btn-light me-2 p-2">
                    <img :src="menu" alt="menu">
            </button>


            <div class="d-none d-sm-block">
                <span class="description-sm poppins-semibold text-black pe-5 text-capitalize">
                    Welcome
                        {{ user_data.first_name }} 
                        {{ user_data.last_name }}
                </span>
            </div>
        </div>

        <div class="flex-center">
	        
	        <div class="position-relative me-3">
		        <input  @input="($event) => store.commit('setSearch', $event.target.value)"
		               class="form-control description-xs px-4 py-2 mb-3 mb-sm-0 rounded-20 border-0"
		               placeholder="Search"
		               type="text"
		               v-model="local_search"
		               style="min-width: 334px; max-width: 334px; width: 100%;">
		        
		        <img class="position-absolute"
		             style="width: 24px; right: 10px; top: 8px;"
		             :src="icon_search" alt="">
	        </div>
            

            <button class="btn btn-light me-2 p-2">
                <img :src="bell" alt="bell">
            </button>

            <button class="btn btn-light p-2">
                <img :src="user" alt="user">
            </button>
        </div>
    </header>
</template>


<script setup>
    // Imports
    import {ref, computed, watch} from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"
    import icon_search from "@/assets/icons/search.svg";

    // Images
    const user = require('ASSETS/icons/header/user')
    const bell = require('ASSETS/icons/header/bell')
    const menu = require('ASSETS/icons/header/menu')

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Injects

    // Emits
    const emits = defineEmits(['hide']);

    // Computeds
    const user_data = computed(() => (store.getters['getUser']))
    
    //variable
    const local_search = ref(null)
    
    watch(() => store.getters.getSearch, (newVal, oldVal) => {
		local_search.value= newVal
    })
    
    

    // Methods
    const hideMenu = () => {
        emits('hide')
    }

    // Vue LifeCycle
    
</script>

<style lang="scss" scoped>
    #Header {
        background-color: #FFFAF1;
        border-radius: 24px;
        height: 82px;
        width: 100%;
    }
</style>