<template>
	<div class="description-xs text-capitalize">
		{{ label }}
		<vue-tel-input class="bg-white description-xxs" @on-input="(number, phone) => updatePhone(number, phone)"
			:defaultCountry="dial_number"
            :autoDefaultCountry="false"
			:preferredCountries="preferredCountries"
			v-model="local_phone">

		</vue-tel-input>
	</div>
</template>

<script setup>
	import {ref, watch, computed} from "vue";

	const props = defineProps({
		modelValue: {
			type: String,
			default: '',
		},
		default_dial: {
			type: [String, Number],
			default: 'US',
		},
		preferredCountries: {
			type: Array,
			default: () => ['MX', 'US'],
		},
		label: {
			type: String,
			default: 'Phone',
		}
	})

	const emits = defineEmits(["update:modelValue"])

	const local_phone = ref('')
	const dial_number = ref('')


	const updatePhone = (number, phone) => {
		if(phone.country == undefined) return;
		const phone_string = `+${phone.country.dialCode} ${phone.nationalNumber ?? ''}`
		emits('update:modelValue', phone_string);
	}


	watch(() => props.modelValue, (new_phone, oldValue) => {

		if(!new_phone) {
			dial_number.value = props.default_dial;
			local_phone.value = 'seelect'
			return;
		}

		const phone_array = new_phone.replace('+','').split(' ')

		local_phone.value = phone_array[1]
		dial_number.value = phone_array[0]
	})

	dial_number.value = props.default_dial

	if(props.modelValue != null) {
		const phone_array = props.modelValue.replace('+','').split(' ')
		console.log('array', phone_array)
		local_phone.value = phone_array[1]
		dial_number.value = Number(phone_array[0])
	}
</script>


<style lang="scss" scoped>
	.vue-tel-input{
		border: none;
		border-radius: 15px;
		height: 40px;

		.vti__dropdown {
			background: white;
		}
	}
</style>