import sendRequest from "@/libs/sendRequest"

const reports = {
    namespaced: true,
    state: {
        reports: [],
        error: false,
        last_page: null,
        total_pages: null
    },
    getters: {
        getReports: (state) => (state.reports),
        getLastPage: (state) => (state.last_page),
        getTotalPages: (state) => (state.total_pages),
        getError: (state) => (state.error)
    },
    mutations: {
        setReports: (state, payload) => state.reports = payload,
        setLastPage: (state, payload) => state.last_page = payload,
        setTotalPages: (state, payload) => state.total_pages = payload,
        setError: (state, payload) => state.error = payload,
    },
    actions: {
        listLogAdmin: async({commit, dispatch, rootGetters}, query) => {
            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/reports/log_user_admin', 'GET', null, query, custom_headers)

            if(response.success && response.data) {
                if(query.datatable) {
                    commit('setReports', response.data.list.result ?? [])
                    commit('setTotalPages', response.data.list.total_pages)

                    if(response.data.list.total_pages == query.page) {
                        commit('setLastPage', true)
                    }
                    else {
                        commit('setLastPage', false)
                    }
                }
                else {
                    commit('setReports', response.data.list)
                }
                dispatch('hiddenLoading', null, { root: true })
            }
            else if(!response.success && response.errors) {
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', 'An error has occurred', { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        listLogTraveller: async({commit, dispatch, rootGetters}, query) => {
            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/reports/log_traveller', 'GET', null, query, custom_headers)

            if(response.success && response.data) {
                if(query.datatable) {
                    commit('setReports', response.data.list.result ?? [])
                    commit('setTotalPages', response.data.list.total_pages)

                    if(response.data.list.total_pages == query.page) {
                        commit('setLastPage', true)
                    }
                    else {
                        commit('setLastPage', false)
                    }
                }
                else {
                    commit('setReports', response.data.list)
                }
                dispatch('hiddenLoading', null, { root: true })
            }
            else if(!response.success && response.errors) {
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', 'An error has occurred', { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        listLogLocalGuide: async({commit, dispatch, rootGetters}, query) => {
            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/reports/log_local_guide', 'GET', null, query, custom_headers)

            if(response.success && response.data) {
                if(query.datatable) {
                    commit('setReports', response.data.list.result ?? [])
                    commit('setTotalPages', response.data.list.total_pages)

                    if(response.data.list.total_pages == query.page) {
                        commit('setLastPage', true)
                    }
                    else {
                        commit('setLastPage', false)
                    }
                }
                else {
                    commit('setReports', response.data.list)
                }
                dispatch('hiddenLoading', null, { root: true })
            }
            else if(!response.success && response.errors) {
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', 'An error has occurred', { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        listLogPartner: async({commit, dispatch, rootGetters}, query) => {
            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/reports/log_partner', 'GET', null, query, custom_headers)

            if(response.success && response.data) {
                if(query.datatable) {
                    commit('setReports', response.data.list.result ?? [])
                    commit('setTotalPages', response.data.list.total_pages)

                    if(response.data.list.total_pages == query.page) {
                        commit('setLastPage', true)
                    }
                    else {
                        commit('setLastPage', false)
                    }
                }
                else {
                    commit('setReports', response.data.list)
                }
                dispatch('hiddenLoading', null, { root: true })
            }
            else if(!response.success && response.errors) {
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', 'An error has occurred', { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        listReservations: async({commit, dispatch, rootGetters}, query) => {
            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/reports/reservations', 'GET', null, query, custom_headers)

            if(response.success && response.data) {
                if(query.datatable) {
                    commit('setReports', response.data.list.result ?? [])
                    commit('setTotalPages', response.data.list.total_pages)

                    if(response.data.list.total_pages == query.page) {
                        commit('setLastPage', true)
                    }
                    else {
                        commit('setLastPage', false)
                    }
                }
                else {
                    commit('setReports', response.data.list)
                }
                dispatch('hiddenLoading', null, { root: true })
            }
            else if(!response.success && response.errors) {
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', 'An error has occurred', { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        listReviews: async({commit, dispatch, rootGetters}, query) => {
            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/reports/reviews', 'GET', null, query, custom_headers)

            if(response.success && response.data) {
                if(query.datatable) {
                    commit('setReports', response.data.list.result ?? [])
                    commit('setTotalPages', response.data.list.total_pages)

                    if(response.data.list.total_pages == query.page) {
                        commit('setLastPage', true)
                    }
                    else {
                        commit('setLastPage', false)
                    }
                }
                else {
                    commit('setReports', response.data.list)
                }
                dispatch('hiddenLoading', null, { root: true })
            }
            else if(!response.success && response.errors) {
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', 'An error has occurred', { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        listCatalogsReport: async({commit, dispatch, rootGetters}, query) => {
            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/reports/log_catalogs', 'GET', null, query, custom_headers)

            if(response.success && response.data) {
                if(query.datatable) {
                    let raw_data = response.data.list.result ?? [];

                    for(let item of raw_data) {
                        item.data = JSON.parse(item.data);
                    }

                    console.log(raw_data);

                    commit('setReports', raw_data)
                    commit('setTotalPages', response.data.list.total_pages)

                    if(response.data.list.total_pages == query.page) {
                        commit('setLastPage', true)
                    }
                    else {
                        commit('setLastPage', false)
                    }
                }
                else {
                    commit('setReports', response.data.list)
                }
                dispatch('hiddenLoading', null, { root: true })
            }
            else if(!response.success && response.errors) {
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', 'An error has occurred', { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },

    }


}

export default reports