<template>
    <aside id="Sidebar" class="bg-primary p-0 flex-start-end flex-column position-relative">
        <div class="row w-100 mt-3">

            <!-- Image Section -->
            <section class="section-image flex-start">
                <div style="max-width: 100px;">
                    <img @click.stop="router.push({ name: session.rol_name === 'administrator' ? 'countries' : 'experiences'})"
                        :src="logo" class="img-fluid" alt="">
                </div>
            </section>

            <!-- Items in menu -->
            <div class="row justify-content-center p-0 w-100">
                <div class="col-12 flex-center my-2"
                     v-for="(menu_object, index) in menu" :key="`menu-${index}`">

                    <!-- without submenu -->
                    <button @click="goToOption(index, menu_object.route)" v-if="!menu_object.hasOwnProperty('submenu') && menu_object.permited_roles.includes(session.rol_name)"
                            @mouseenter="resetMenu"
                            class="btn flex-start-center border-0 custom_size custom-hover d-flex"
                            :class="[(menu_active === menu_object.label) ? 'active' : '']"
                            type="button">
                        <img style="width: 20px;" :src="menu_object.icon" :alt="menu_object.label">

                        <span class="ms-2">
                        {{ menu_object.label }}
                    </span>

                    </button>

                    <!-- with submenu -->
                    <button @mouseenter="openMenu(index)" v-if="menu_object.hasOwnProperty('submenu') && menu_object.permited_roles.includes(session.rol_name)"
                            class="btn d-flex flex-start-center position-relative custom_size custom-hover border-0"
                            :class="[(menu_active) == menu_object.label ? 'active' : '']"
                            type="button">

                        <img style="width: 20px" :src="menu_object.icon" :alt="menu_object.label">

                        <span class="ms-2">
                            {{ menu_object.label}}
                        </span>


                        <div v-if="menu_object.open"
                             @mouseleave="menu_object.open = false; console.log('leaved_submenu');"
                             class="submenu_wrapper row p-0"
                             style="z-index: 100;">

	                        <template v-for="(option, index) in menu_object.submenu" :key="`submenu-${index}`">
		                        <button v-if="option.permited_roles.includes(session.rol_name)"
			                        @click="() => {router.push({ name: option.route , params: { type: option.params} }); menu_object.open = false; store.commit('setSearch', '') }"
			                        class="menu_btn"
			                        style="width: 180px; z-index: 110;"
			                        type="button">
			                        {{ option.label}}
		                        </button>
	                        </template>

                        </div>
                    </button>
                </div>

                
                <div class="col-12 position-relative" v-if="session.rol_name === 'administrator'">
	                <button @click.stop="open_menu = !open_menu"
                        @mouseenter="resetMenu"
                        class="btn flex-start-center border-0 custom_size custom-hover d-flex"
                        type="button">
		                
		                <img style="width: 20px;" :src="gear" alt="settings">
		                
		                <span class="ms-2 text-capitalize">
			                Settings
                        </span>
	                
	                </button>
	                
	                
	               <ul class="overflow-y-scroll list-unstyled" style="height: 60%;" v-if="open_menu">
		               <li v-for="(button, index) in dropmenu" :key="`dropmenu-${index}`">
			               <button class="label_button poppins-semibold" @mouseenter.stop="selectMenu(button.submenu)"
			                    type="button">
				               {{ button.label }}
			               </button>
		               </li>
	               </ul>
	                
	                <div class="position-absolute context-menu-wrapper" v-if="open_submenu"
                         @mouseleave="open_submenu = false">
		                <button @click.stop="goToOption(6, route.route)" v-for="(route, index) in selected_menu" :key="`seleted_menu-${index}`"
	                        class="d-block context-menu-btn poppins-semibold"
		                    type="button">
			                {{ route.label }}
		                </button>
	                </div>
	                
                </div>
                
            </div>

        </div>

        <!-- Logout button -->
        <div class="row flex-center position-absolute" style="bottom: 3%; left: 10%;">
            <button @click.stop="endsession"
                class="btn flex-start-center border-0 custom_size ms-2"
                type="button">

                <div style="max-width: 34px; margin-left: -7px;">
                    <img style="width: 30px" :src="logout" class="img-fluid" alt="logout">
                </div>
                <span class="poppins-semibold description-xxs ms-2 text-capitalize">
                    Log out
                </span>
            </button>
        </div>
    </aside>
</template>

<script setup>
    // Imports
    import { computed, ref, inject } from 'vue'
    import { useStore } from 'vuex'
    import { useRouter } from 'vue-router'

    // Images
    const logo = require('ASSETS/images/logo')
    const gear = require('ASSETS/icons/sidebar/gear')
    const user = require('ASSETS/icons/sidebar/user')
    const landscape = require('ASSETS/icons/sidebar/landscape')
    const tachometer = require('ASSETS/icons/sidebar/tachometer')
    const devices = require('ASSETS/icons/sidebar/devices')
    const message_square = require('ASSETS/icons/sidebar/message_square')
    const logout = require('ASSETS/icons/sidebar/logout')
    
    // Hooks
    const store = useStore()
    const router = useRouter()

    // Injects
    const $redirect = inject('$redirect')

    // Computeds
    const current_router_name = computed(() => {
        return router.currentRoute.value.name
    })

    const session = computed(() => (store.getters.getUser))

    const menu_active = computed(() => {
        for(let option_menu of menu.value) {
            if(option_menu.submenu !== undefined && option_menu.submenu !== null) {
                // Option With Submenu
                for(let option_submenu of option_menu.submenu) {
                    if(option_submenu.route === current_router_name.value) {
                        return option_menu.label
                    }
                }
            }
            else {
                // Option Without Submenu
                if(option_menu.route === current_router_name.value) {
                    return option_menu.label
                }
            }
        }
    })

    // Variables
    const menu = ref([
        {
            label: 'Users',
            icon: user,
            open: false,
	        permited_roles: ['administrator'],
            submenu: [
                {
                    label: 'Users',
                    route: 'users',
	                permited_roles: ['administrator']
                },
                {
                    label: 'LocalGuides',
                    route: 'local-guides',
	                permited_roles: ['administrator']
                },
                {
                    label: 'Travellers',
                    route: 'travellers',
	                permited_roles: ['administrator']
                },
                {
                    label: 'Partners',
                    route: 'partners',
	                permited_roles: ['administrator']
                }
            ]
        },
        {
            label: 'Experiences',
            icon: landscape,
            open: false,
	        permited_roles: ['administrator','partner'],
	        submenu: [
		        {
					label: 'Experiences',
			        route: 'experiences',
			        permited_roles: ['administrator', 'partner']
		        },
		        {
					label: 'Categories',
			        route: 'experience-categories',
			        permited_roles: ['administrator']
		        },
		        {
			        label: 'Experience Duration',
			        route: 'experience-durations',
			        permited_roles: ['administrator']
		        }
	        ]
        },
        {
            label: 'Reservations',
            icon: devices,
            route: 'reservations',
	        permited_roles: ['administrator'],
        },
        {
            label: 'Reports',
            icon: tachometer,
            open: false,
	        permited_roles: ['administrator'],
            submenu: [
                {
                    label: 'Log Users',
                    route: 'report-log-admin',
	                permited_roles: ['administrator']
                },
                {
                    label: 'Log Local Guide',
                    route: 'report-log-local-guide',
	                permited_roles: ['administrator']
                },
                {
                    label: 'Log Traveler',
                    route: 'report-log-traveller',
	                permited_roles: ['administrator']
                },
                {
                    label: 'Log Partner',
                    route: 'report-log-partner',
	                permited_roles: ['administrator']
                },
	            {
		            label: 'Rerserves',
		            route: 'report-experiences',
		            permited_roles: ['administrator']
	            },
                {
                    label: 'Reviews',
                    route: 'report-reviews',
	                permited_roles: ['administrator']
                },
	            {
		            label: 'Experiences',
		            route: 'report-catalogs',
		            permited_roles: ['administrator']
	            },
            ]
        },
        {
            label: 'Support',
            icon: message_square,
            open: false,
	        permited_roles: ['administrator'],
	        submenu: [
		        {
					label: 'Travelers',
			        route: 'support-chat',
			        params: 'traveller',
                    permited_roles: ['administrator']
		        },
		        {
			        label: 'Local Guide',
			        route: 'support-chat',
			        params: 'local_guide',
                    permited_roles: ['administrator']
		        }
	        ]
        },
    ])
    
    const open_menu = ref(false);
	const open_submenu = ref(false);

    const dropmenu = ref([
        {
            label: 'Location',
            open: false,
            submenu: [
                { label: 'Languages', route: 'languages' },
                { label: 'Countries', route: 'countries' },
                { label: 'States', route: 'states' },
                { label: 'Cities', route: 'cities' },
            ]
        },
        {
            label: 'Vehicles',
            open: false,
            submenu: [
                { label: 'Colors', route: 'colors'},
                { label: 'Brands', route: 'vehicle-brands' },
                { label: 'Models', route: 'vehicle-models' },
            ]
        },
        {
            label: 'Places',
            open: false,
            submenu: [
                { label: 'Amenities', route: 'amenities'},
                { label: 'Services', route: 'services'},
            ]
        },
        {
            label: 'Partners',
            open: false,
            submenu: [
                { label: `Partner's Categories`, route: 'partner-categories'},
            ]
        },
        {
            label: 'Other',
            open: false,
            submenu: [
                { label: 'Skills', route: 'skills' },
                { label: 'FAQ', route: 'faqs' },
                { label: 'FAQ sections', route: 'faq-sections' },
                { label: 'Banks', route: 'banks' },
                { label: 'Documents', route: 'documents' },
                { label: 'Currencies', route: 'currencies' },
            ]
        }

    ])

    const current_index = ref(0)
    const selected_menu = ref([]);

    // Methods
    const goToOption = (index, route) => {
		store.commit('setSearch', '')
        current_index.value = index
	    console.log('route:', route)
        $redirect(route)
    }

    const resetMenu = () => {
        for(let key in menu.value) {
            menu.value[key].open = false;
        }
    }

    const openMenu = (index) => {

        for(let key in menu.value) {
            menu.value[key].open = false;
        }

        menu.value[index].open = true;
        current_index.value = index

    }
    
    const selectMenu = (menu) => {
		open_submenu.value = true;
		selected_menu.value = menu;
    }

    const endsession = async () => {
        await sessionStorage.removeItem('farrther@session')
        $redirect('login')
    }

    // Vue LifeCycle

</script>

<style lang="scss" scoped>
    @use 'sass:color';
    @import 'ASSETS/scss/minxin.scss';

    #Sidebar {
        position: fixed;
        width: 240px;
        height: calc(100vh);

        .section-image {
            margin: 1.25rem 0 5rem 0;
        }

        .active {
            background-color: color.adjust(#133831, $lightness: -5%);
        }

        .custom_size {
            height: 40px;
            width: 100% !important;

            @include downPixels(1440px) {
                width: 100%;
                height: 40px;
                font-size: 14px;
            }

            img {
                width: 40px;
                height: 40px;

                @include downPixels(1440px) {
                    width: 25px;
                    height: 25px;
                }
            }

            .submenu_wrapper {
                position: absolute;
                right: -115% ;
                top: 0%;

                background-color: white;
                border-radius: 10px;
                padding: 14px 10px;
                width: 200px;
                box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);

                @include downPixels(1440px) {
                    right: -125%;
                    top: 5%;
                }

                .menu_btn {
                    flex-grow: 1;
                    text-align: start;
                    height: 40px;
                    color: black;
                    background-color: white;
                    border: none;
                    transition: 0.4s all;

                    &:first-of-type {
                        border-top-left-radius: inherit;
                        border-top-right-radius: inherit;
                    }

                    &:last-of-type {
                        border-bottom-left-radius: inherit;
                        border-bottom-right-radius: inherit;
                    }

                    &:hover {
                        background-color: var(--bs-primary);
                        color: white;
                    }
                }
            }
        }
	    
	    .label_button {
		    background-color: transparent;
		    color: white;
		    border: none;
		    padding-left: 40px;
            margin: 5px 0;
	    }
	    
	    .context-menu-wrapper {
		    top: 0;
		    left: 105%;
		    width: 200px;
		    background-color: white;
		    border-radius: 20px;
		    
		    .context-menu-btn {
			    width: 100%;
			    text-align: start;
			    height: 40px;
			    color: black;
			    background-color: white;
			    border: none;
			    transition: 0.4s all;
			    padding: 0px 15px;
			    
			    &:first-of-type {
				    border-top-left-radius: inherit;
				    border-top-right-radius: inherit;
			    }
			    
			    &:last-of-type {
				    border-bottom-left-radius: inherit;
				    border-bottom-right-radius: inherit;
			    }
			    
			    &:hover {
				    background-color: var(--bs-primary);
				    color: white;
			    }
		    }
	    }

        .custom-hover {
            transition: 0.4s background-color, 0.4s color;

            &:hover {
                background-color: color.adjust(#133831, $lightness: -5%);
            }
        }
    }
</style>
