<template>
	<span class="label_span text-center">
		{{ label }}
	</span>
	<div :class="`wrapper-${theme}-${variant}`"
		@dragleave="($event) => {$event.preventDefault()}"
	    @dragover="($event) => {$event.preventDefault()}"
		@drop="drop($event)"
		
		:style="`border-radius: ${border_radius};`">
		
		<div class="file_wrapper" v-if="!preview && !loading">
			<span>
				Drop a file
				<br> or
			</span>
			<label for="file">
				Select File
				<input @change="fileChange($event)"
				       type="file" name="file" id="file" hidden>
			</label>
		</div>
		
		<div v-if="loading">
			Processing...
		</div>
		
		<div class="preview_wrapper" v-if="preview && !loading">
			<img @click.stop="show_preview = true"
			     :src="preview" alt="">
			
			<button @click.stop="preview = ''"
			        type="button">
				Cambiar
			</button>
		</div>
	</div>
	
	<div  @click.stop="show_preview = false;" v-if="show_preview"
		class="d-flex justify-content-center align-items-center"
        style="width: 100vw; height: 100vh; background-color: rgba(0,0,0, 0.5); position: fixed; top: 0; left: 0; z-index: 200;">
		<img :src="preview" style="width: 90%; height: 90%; object-fit: scale-down;" alt="">
	</div>
</template>

<script setup>
	import { ref, watch} from "vue";
	
	//props
	const props = defineProps({
		modelValue: {
			type: String
		},
		border_radius: {
			type: String,
			required: false,
			default: '10px'
		},
		label: {
			type: String,
			required: false,
			default: 'Default label'
		},
		theme: {
			type: String,
			required: false,
			default: 'light'
		},
		variant: {
			type: String,
			required: false,
			default: 'primary'
		}
	})
	
	//emits
	const emits = defineEmits(['update:modelValue']);
	
	//Browser API's
	const fr = new FileReader();
	
	//variables
	const preview = ref('');
	const loading = ref(false);
	const show_preview = ref(false);
	
	//methods
	const drop = (event) => {
		event.preventDefault();
		loading.value = true
		if(preview.value) return;
		let file = event.dataTransfer.files[0]
		fr.readAsDataURL(file);
		fr.addEventListener("loadend", () => {
			preview.value = fr.result;
			loading.value = false
			emits('update:modelValue', fr.result);
		});
	}
	
	const fileChange = (event) => {
		let file = event.target.files[0];
		loading.value = true
		fr.readAsDataURL(file);
		fr.addEventListener("loadend", () => {
			preview.value = fr.result;
			loading.value = false
			emits('update:modelValue', fr.result);
			event.target.value = null
			file = null
		});
	}

    watch(() => props.modelValue, (newVal) => {
        preview.value = newVal
    })

	
	//vue life cycle
	preview.value = props.modelValue

</script>

<style lang="scss" scoped>

	@use 'sass:color';
	
	$themes: 'dark', 'light';
	$variants: (
		'primary': #78A083,
		'secondary': #344955,
		'info': #205295,
		'success': #03C988,
		'warning': #FEC260,
		'danger': #DA0037
	);
	
	@each $theme in $themes {
		@each $variant, $color in $variants {
			
			//== Label styles ==//
			.label_span {
				width: 100%;
				display: block;
				margin-bottom: 5px;
				@if($theme == 'dark') {
					color: white;
				}
				
				@else {
					color: black;
				}
				font-size: 1.2em;
			}
			
			//== file styles ==//
			.wrapper-#{$theme}-#{$variant} {
				width: 200px;
				height: 200px;
				border: 1px solid $color;
				color: White;
				display: flex;
				justify-content: center;
				align-items: center;
				
				
				.file_wrapper {
					width: 100%;
					height: 100%;
					background-color: transparent;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					text-align: center;
					border-radius: inherit;
                    color: black;
					
					label {
						padding: 5px 20px;
						border-radius: inherit;
						transition: all 0.4s linear;
						position: relative;
                        color: black;
						display: inline-block;
						
						&::before, &::after {
							content: '';
							width: 20px;
							height: 20px;
							position: absolute;
							transition: all 0.4s linear;
						}
						
						&::before {
							top: 0;
							left: 0;
							border-top: 1px solid $color;
							border-left: 1px solid $color;
						}
						
						&::after {
							bottom: 0;
							right: 0;
							border-right: 1px solid $color;
							border-bottom: 1px solid $color;
						}
						
						&:hover::before, &:hover::after {
							width: 100%;
							height: 100%;
						}
					}
				}
				
				.preview_wrapper {
					width: 100%;
					height: 100%;
					border-radius: inherit;
					display: flex;
					flex-direction: column;
					position: relative;
					
					img {
						width: 100%;
						height: 80%;
						object-fit: cover;
						border-top-left-radius: inherit;
						border-top-right-radius: inherit;
					}
					
					button {
						width: 100%;
						border: none;
						flex-grow: 1;
						border-radius: inherit;
						margin-top: 10px;
						background-color: $color;
						@if($theme == 'dark') {
							color: white;
						}
						@else {
							color: black;
						}
					}
					
					&::before {
						content: 'full preview';
						width: 100%;
						height: 50%;
						display: flex;
						justify-content: center;
						align-items: center;
						color: $color;
						font-weight: bold;
						font-size: 20px;
						background-color: rgba(0, 0, 0, 0.8);
						top: 20%;
						opacity: 0;
						position: absolute;
						transition: all 0.4s linear;
						pointer-events: none;
					}
					
					&:hover::before {
						opacity: 1;
					}
				}
			}
		}
	}
	
</style>