<template>
    <span class="label poppins-regular">
        {{ label }}
    </span>

    <div class="selector_wrapper">

        <!---  current options --->
        <div class="current_options">

            <div class="current_options_container">

                <div class="option poppins-regular" v-for="(item, index) in selected_options" :key="index">
                    <span >
                        {{ item.label }}
                    </span>
                    <button @click.stop="removeOption(item)"
                        type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="remove-icon" >
                            <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
                        </svg>
                    </button>
                </div>

            </div>

            <button
                @click.stop="open"
                class="add_btn"
                type="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="add-icon">
                    <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path>
                </svg>
            </button>

        </div>

        <div class="options_wrapper" v-if="show_options">
            <input @input="filterOptions"
               v-model="search_query"
               type="text"
               name="search"
               ref="input_search"
               id="search"
               placeholder="Search...">

            <p v-for="(item, index) in local_options" :key="index"
                @click.stop="addOption(item)">
                {{ item.label}}
            </p>
        </div>
    </div>
</template>

<script setup>

import {onMounted, ref, watch} from "vue";

    const props = defineProps({
        label: {
            type: String,
            required: false,
            default: 'Default label'
        },
        options: {
            type: Array,
            required: false,
            default: () => (
                [
                    {id: 1, label: 'Español'},
                    {id: 2, label: 'Ingles'},
                    {id: 3, label: 'Aleman'},
                    {id: 4, label: 'Chino'},
                    {id: 5, label: 'Japones'},
                    {id: 6, label: 'Koreano'},
                    {id: 7, label: 'Irlandes'},
                    {id: 8, label: '1'},
                    {id: 9, label: '2'},
                    {id: 10, label: 'Nombre excesivamente largo'},
                ]
            ),
        },
        modelValue: {
            type: [Array, null]
        }
    })

    //emits
    const emit = defineEmits(["update:modelValue"]);

    //variables
    const local_options = ref([]);
    const selected_options = ref([]);
    const show_options = ref(false);
    const search_query = ref('');
    const input_search = ref(null);

    //computed


    //methods
    const addOption = (option) => {
        const index = selected_options.value.findIndex((option_item) => option_item.id === option.id);

        if(index > -1) return

        selected_options.value.push(option);
        show_options.value = false;
        emit('update:modelValue', selected_options.value.map((option) => option.id));
    };

    const removeOption = (option) => {
        const index = selected_options.value.findIndex((option_item) => option_item.id === option.id);
        delete selected_options.value[index];
        selected_options.value = selected_options.value.filter(() => true);
        emit('update:modelValue', selected_options.value.map((option) => option.id));
    }

    const open = () => {
        show_options.value = !show_options.value;
        setTimeout(() => {
            if(show_options.value) {
                input_search.value.focus();
            }
        })
    }

    const filterOptions = () => {
        if(!search_query.value) {
            local_options.value = [...props.options];
        }
        else {
            local_options.value = local_options.value.filter((item) => item.label.toLowerCase().includes(search_query.value.toLowerCase()));
        }

        console.log(local_options.value)
    }

    //watchers
    watch(() => props.options, (first, second) => {
        local_options.value = [...props.options];
    });

    watch(() => props.modelValue, (value) => {

        selected_options.value = [];

        for(let id of value) {
            let option = props.options.find((item) => item.id === id);

            if(option) {
                selected_options.value.push(option);
            }

        }

    });

    //Vue lifecycle
    onMounted(() => {
        local_options.value = [...props.options];

        if(props.modelValue) {
            for(let id of props.modelValue) {
                let option = props.options.find((item) => item.id === id);
                selected_options.value.push(option);
            }
        }
    });


</script>

<style lang="scss" scoped>

    //colors
    $general-container-color: white;
    $add-button-color: #133831;
    $icon-color: white;
    $option-color: black;
    $chip-color: #133831;
    $label-color: black;

    .label {
        color: $label-color;
    }

    .selector_wrapper {
        width: 100%;
        min-height: 40px;
        background-color: $general-container-color;
        border-radius: 5px;
        position: relative;


        
        .current_options {
            display: flex;

            .current_options_container {
                flex-grow: 1;
                display: flex;
                flex-wrap: wrap;
                align-items: center;


                .option{
                    background-color: $chip-color;
                    margin: 2px;
                    border-radius: 100px;
                    display: flex;
                    color: white;

                    span {
                        margin: 0 10px;
                    }

                    button {
                        background-color: $chip-color;
                        border-radius: 50%;
                        width: 25px;
                        height: 25px;
                        margin: 2px;
                        padding: 0;
                        border: none;
                        display: flex;

                        .remove-icon {
                            fill: $icon-color;
                        }
                    }
                }


            }

            .add_btn {
                width: 40px;
                height: 40px;
                border: none;
                border-top-right-radius: inherit;
                border-bottom-right-radius: inherit;
                background-color: $add-button-color;

                .add-icon {
                    fill: $icon-color;
                }
            }
        }

        .options_wrapper {
            width: inherit;
            height: 250px;
            overflow-y: scroll;
            background-color: $general-container-color;

            input {
                width: inherit;
                margin-top: 2px;
                height: 40px;
                position: absolute;
            }

            p:first-of-type {
                margin-top: 50px;
            }

            p {
                color: $option-color;
                margin-left: 0.5em;
                transition: all 0.4s;

                &:hover {
                    color: white;
                    border-bottom: 1px solid white;
                }
            }
        }

    }
</style>