import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Import Global Configuration
global.CONFIG = require('./page.config.js')


// import Global Styles
import 'ASSETS/scss/custom.scss'


// imports LIBS
import _informativeDate from './libs/informativeDate.js'
import _sendRequest from './libs/sendRequest.js'
import _redirect from './libs/redirect.js'


// import Global Components
import {
    Alert,
    Loading,
    Header,
    Sidebar,
    DropDown,
    Select,
    FloatMenu,
    Modal,
    InputRecovery,
    Table,
    DropFile,
    Steps,
    MultiSelect,
    Checkbox,
    ContextMenu,
    BreadCrumb,
    ChatParticipant,
    InterPhoneInput,
    InputPassword,
    DateInput
} from './components/index.js'

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'


// TEMPLATES
import T_login from 'TEMPLATES/Tlogin'
import T_dashboard from 'TEMPLATES/Tdashboard'


// Create App
const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueTelInput);
// Templates
app.component('login', T_login)
app.component('dashboard', T_dashboard)
// Components
app.component('fa-alert', Alert)
app.component('fa-loading', Loading)
app.component('fa-header', Header)
app.component('fa-sidebar', Sidebar)
app.component('fa-select', Select)
app.component('fa-dropdown', DropDown)
app.component('fa-modal', Modal)
app.component('fa-input-recovery', InputRecovery)
app.component('fa-table', Table)
app.component('fa-floatmenu', FloatMenu)
app.component('fa-dropfile', DropFile)
app.component('fa-steps', Steps)
app.component('fa-multy-select', MultiSelect)
app.component('fa-check', Checkbox)
app.component('fa-context-menu', ContextMenu)
app.component('fa-bread-crumb', BreadCrumb)
app.component('fa-chat-participant', ChatParticipant)
app.component('fa-inter-input', InterPhoneInput)
app.component('fa-input-password', InputPassword)
app.component('fa-date-picker', VueDatePicker)
// Libs
app.provide('$informativeDate', _informativeDate)
app.provide('$sendRequest', _sendRequest)
app.provide('$redirect', _redirect)
// Mount
app.mount('#app')