module.exports = {
	env: "prod",
	server: {
		local: {
			page_url: 'http://localhost:8080/',
			api: 'http://localhost:3000',
			headers: {
            	'Content-Type': 'application/json',
				"reference": "com.farrther.web",
				'Authorization': 'Bearer ZDJlZjNmZWQyZWVjZWQ1ZjA5NzE5ZjhmZGIyMzM0YzNiMDJlZjFiOTlhMjgzYTFkYWE5MjI4NmJhOTk4YzhmY2FjM2Q1OTFjMzM4NDQ1ODI0MzY2ZTA4YTk5NWVhZDU2',		
			}
		},
		dev: {
			page_url: 'https://admin.farrther.online/',
			api: 'https://api.farrther.online',
			headers: {
            	'Content-Type': 'application/json',
				"reference": "com.farrther.web",
				'Authorization': 'Bearer ZDJlZjNmZWQyZWVjZWQ1ZjA5NzE5ZjhmZGIyMzM0YzNiMDJlZjFiOTlhMjgzYTFkYWE5MjI4NmJhOTk4YzhmY2FjM2Q1OTFjMzM4NDQ1ODI0MzY2ZTA4YTk5NWVhZDU2',
			}
		},
		prod: {
			page_url: 'https://admin.farrther.online/',
			api: 'https://api.farrther.online',
			headers: {
            	'Content-Type': 'application/json',
				"reference": "com.farrther.web",
				'Authorization': 'Bearer ZDJlZjNmZWQyZWVjZWQ1ZjA5NzE5ZjhmZGIyMzM0YzNiMDJlZjFiOTlhMjgzYTFkYWE5MjI4NmJhOTk4YzhmY2FjM2Q1OTFjMzM4NDQ1ODI0MzY2ZTA4YTk5NWVhZDU2',
			}
		},
	},
}