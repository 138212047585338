import sendRequest from "@/libs/sendRequest"

const users = {
    namespaced: true,
    state: {
        user: {},
        users: [],
        error: false,
        last_page: null,
        total_pages: null
    },
    getters: {
        getUserSingle: (state) => (state.user),
        getUsers: (state) => (state.users),
        getLastPage: (state) => (state.last_page),
        getTotalPages: (state) => (state.total_pages),
        getError: (state) => (state.error)
    },
    mutations: {
        setUser: (state, payload) => state.user = payload,
        setUsers: (state, payload) => state.users = payload,
        setLastPage: (state, payload) => state.last_page = payload,
        setTotalPages: (state, payload) => state.total_pages = payload,
        setError: (state, payload) => state.error = payload,
    },
    actions: {
        list: async({commit, dispatch, rootGetters}, query) => {
            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/users/users/list', 'GET', null, query, custom_headers)

            if(response.success && response.data) {
                if(query.datatable) {
                    commit('setUsers', response.data.list.result)
                    commit('setTotalPages', response.data.list.total_pages)

                    if(response.data.list.total_pages == query.page) {
                        commit('setLastPage', true)
                    }
                    else {
                        commit('setLastPage', false)
                    }
                }
                else {
                    commit('setUsers', response.data.list)
                }
                dispatch('hiddenLoading', null, { root: true })
            }
            else if(!response.success && response.errors) {
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', 'An error has occurred try later', { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        view: async({commit, dispatch, rootGetters}, query) => {
            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/users/users/view', 'GET', null, query, custom_headers)

            if(response.success && response.data) {
                commit('setUser', response.data.view)
                dispatch('hiddenLoading', null, { root: true })
            }
            else if(!response.success && response.errors) {
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', 'An error has occurred try later', { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        add: async({commit, dispatch, rootGetters}, body) => {
            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/users/users/add', 'POST', body, null, custom_headers)

            if(response.success && response.data) {
                dispatch('showAlert', 'The users has been added successfully', { root: true })
                commit('setError', false)
            }
            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    switch(response.errors[error].code) {
                        case 'error_empty_name':
                            messages.push('Error: Please add a name.')
                            break
                        case 'error_user_has_already_exist':
                            messages.push('Error: The users already exists.')
                            break
                        default:
                            messages.push('An error has occurred try later')
                            break
                    }
                }
                commit('setError', true)
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', messages, { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        update: async({commit, dispatch, rootGetters}, data) => {
            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/users/users/update', 'PUT', data.body, data.query, custom_headers)

            if(response.success && response.data) {
                dispatch('showAlert', 'The users has been successfully updated', { root: true })
                commit('setError', false)
            }
            else if(!response.success && response.errors) {
                let messages = []

                for(let error in response.errors) {
                    switch(response.errors[error].code) {
                        case 'error_empty_name':
                            messages.push('Error: Please add a name.')
                            break
                        case 'error_user_inactive':
                            messages.push('Error: Users is inactive')
                            break
                        case 'error_user_has_already_exist':
                            messages.push('Error: The users already exists.')
                            break
                        default:
                            messages.push('An error has occurred try later')
                            break
                    }
                }
                commit('setError', true)
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', messages, { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        delete: async({commit, dispatch, rootGetters}, id) => {
            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/users/users/delete','DELETE', null, { id_user: id }, custom_headers)

            if(response.success && response.data) {
                dispatch('showAlert', 'The users has been successfully removed', { root: true })
                commit('setError', false)
            }
            else if(!response.success && response.errors) {
                commit('setError', true)
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', 'An error has occurred try later', { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        }
    }
}

export default users