<template>
	<label class="description-xs me-3 text-dark w-100 position-relative">
		{{ label}}:
		<input @input="emits('update:modelValue', $event.target.value)"
		       class="custom-input description-xs"
		       :type="password_type">
		<img @click.stop="password_type = 'text'" v-if="password_type == 'password'"
		     class="position-absolute"
		     width="24" height="24"
		     style="top: 50%; right: 2%;"
		     :src="icon_eye"
		     alt="show password">
		<img @click.stop="password_type = 'password'" v-if="password_type == 'text'"
		     class="position-absolute"
		     width="24" height="24"
		     style="top: 50%; right: 2%;"
		     :src="icon_eye_slash"
		     alt="hide password">
	</label>
</template>

<script setup>

	import {ref} from "vue";

	//icons
	const icon_eye = ref(require('ASSETS/icons/eye-icon'))
	const icon_eye_slash = ref(require('ASSETS/icons/eye-slash-icon'))

	//props
	const props = defineProps({
		modelValue: String,
		label: String
	})

	const emits = defineEmits(['update:modelValue'])


	//variables
	const password_type = ref('password')

</script>