import sendRequest from "@/libs/sendRequest"

const currencies = {
    namespaced: true,
    state: {
        currency: {},
        currencies: [],
        error: false,
        last_page: null,
        total_pages: null
    },
    getters: {
        getCurrency: (state) => (state.currency),
        getCurrencies: (state) => (state.currencies),
        getLastPage: (state) => (state.last_page),
        getTotalPages: (state) => (state.total_pages),
        getError: (state) => (state.error)
    },
    mutations: {
        setCurrency: (state, payload) => state.currency = payload,
        setCurrencies: (state, payload) => state.currencies = payload,
        setLastPage: (state, payload) => state.last_page = payload,
        setTotalPages: (state, payload) => state.total_pages = payload,
        setError: (state, payload) => state.error = payload,
    },
    actions: {
        list: async({commit, dispatch, rootGetters}, query) => {
            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/catalogs/currencies/list', 'GET', null, query, custom_headers)

            if(response.success && response.data) {
                if(query.datatable) {
                    commit('setCurrencies', response.data.list.result)
                    commit('setTotalPages', response.data.list.total_pages)

                    if(response.data.list.total_pages == query.page) {
                        commit('setLastPage', true)
                    }
                    else {
                        commit('setLastPage', false)
                    }
                }
                else {
                    commit('setCurrencies', response.data.list)
                }
                dispatch('hiddenLoading', null, { root: true })
            }
            else if(!response.success && response.errors) {
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', 'An error has occurred try later', { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        view: async({commit, dispatch, rootGetters}, query) => {
            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/catalogs/currencies/view', 'GET', null, query, custom_headers)

            if(response.success && response.data) {
                commit('setCurrency', response.data.view)
                dispatch('hiddenLoading', null, { root: true })
            }
            else if(!response.success && response.errors) {
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', 'An error has occurred try later', { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        add: async({commit, dispatch, rootGetters}, body) => {
            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/catalogs/currencies/add', 'POST', body, null, custom_headers)

            if(response.success && response.data) {
                dispatch('showAlert', 'El país ha sido añadido con éxito', { root: true })
                commit('setError', false)
            }
            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    switch(response.errors[error].code) {
                        case 'error_empty_name':
                            messages.push('Error: Please add a name.')
                            break
                        case 'error_currency_has_already_exist':
                            messages.push('Error: El País ya existe.')
                            break
                        default:
                            messages.push('An error has occurred try later')
                            break
                    }
                }
                commit('setError', true)
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', messages, { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        update: async({commit, dispatch, rootGetters}, data) => {
            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/catalogs/currencies/update', 'PUT', data.body, data.query, custom_headers)

            if(response.success && response.data) {
                dispatch('showAlert', 'El país se ha actualizado con éxito', { root: true })
                commit('setError', false)
            }
            else if(!response.success && response.errors) {
                let messages = []

                for(let error in response.errors) {
                    switch(response.errors[error].code) {
                        case 'error_empty_name':
                            messages.push('Error: Please add a name.')
                            break
                        case 'error_currency_inactive':
                            messages.push('Error: El pais se encuentra inactivo')
                            break
                        case 'error_currency_has_already_exist':
                            messages.push('Error: El País ya existe.')
                            break
                        default:
                            messages.push('An error has occurred try later')
                            break
                    }
                }
                commit('setError', true)
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', messages, { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        },
        delete: async({commit, dispatch, rootGetters}, id) => {
            dispatch('showLoading', null, { root: true })
            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('admin/catalogs/currencies/delete','DELETE', null, { id_currency: id }, custom_headers)

            if(response.success && response.data) {
                dispatch('showAlert', 'El país se ha eliminado con éxito', { root: true })
                commit('setError', false)
            }
            else if(!response.success && response.errors) {
                commit('setError', true)
                dispatch('hiddenLoading', null, { root: true })
                dispatch('showError', 'An error has occurred try later', { root: true })
            }
            else if(!response.success && !response.data) {
                dispatch('lostConection', null, { root: true })
            }
        }
    }
}

export default currencies