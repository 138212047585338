// Vuex
import { createStore, createLogger } from 'vuex'
// Modules
import { login, countries, states, cities, languages, colors, vehicle_brands, vehicle_models, amenities, partner_categories, services, skills, faqs, faq_sections, users, travellers, roles, banks, documents, local_guides, partners, experiences_categories, experiences_durations, experiences, support_chat, currencies, reservations, reports} from './modules'
// Libs
import sendRequest from '@/libs/sendRequest'


export default createStore({
    state: {
        loading: {
            show: false,
            type: 'ellipsis',
            title: 'Cargando',
            subtitle: 'un momento...'
        },
        alert: {
            open: false,
            message: 'Error',
            variant: 'danger',
            color_text: 'white',
            timeout: null,
        },
        session: null,
        user_data: null,
        search: ''
    },
    getters: {
        getSession: state => {
            state.session = sessionStorage.getItem('farrther@session') || null
            return state.session
        },
        getUser: state => {
            state.user_data = JSON.parse(sessionStorage.getItem('farrther@user_data')) || null
            return state.user_data
        },
        getLoading: (state) => {
            return state.loading
        },
        getAlert: (state) => {
            return state.alert
        },
        getHeaders (state) {
            return {
                ...CONFIG.server[CONFIG.env].headers,
                session: sessionStorage.getItem('farrther@session')
            }
        },
        getSearch: (state) => (state.search),
    },
    mutations: {
        setLoading: (state, new_load) => {
            state.loading.show = new_load.show
            state.loading.type = (new_load.type !== undefined && new_load.type !== null) ? new_load.type : 'ellipsis'
            state.loading.title = (new_load.title !== undefined && new_load.title !== null) ? new_load.title : 'Loading'
            state.loading.subtitle = (new_load.subtitle !== undefined && new_load.subtitle !== null) ? new_load.subtitle : 'Wait a moment...'
            state.loading.timeout = (new_load.timeout !== undefined && new_load.timeout !== null) ? new_load.timeout : null

            if(state.loading.timeout !== null) {
                setTimeout(() => {
                    state.loading.show = false
                }, state.loading.timeout)
            }
        },
        setAlert: (state, new_alert) => {
            state.alert.open = new_alert.open
            state.alert.message = (new_alert.message !== undefined) ? new_alert.message : ''
            state.alert.variant = (new_alert.variant !== undefined) ? new_alert.variant : 'success'
            state.alert.text_color = (new_alert.text_color !== undefined) ? new_alert.text_color : 'white'
            state.alert.timeout = (new_alert.timeout !== undefined) ? new_alert.timeout : 3000

            if(state.alert.timeout !== null) {
                setTimeout(() => {
                    state.alert.open = false
                }, state.alert.timeout)
            }
        },
        setSession: (state, session_data) => {
            state.session = session_data.session
            state.user_data = session_data.user
            sessionStorage.setItem('farrther@session', session_data.session)
            sessionStorage.setItem('farrther@user_data', JSON.stringify(session_data.user))
        },
        setSearch: (state, payload) => state.search = payload
    },
    actions: {
        // Session
        createSession: ({commit}, session_data) => {
            commit('setSession', session_data)
        },
        existSession: (state) => {
            state.session = sessionStorage.getItem('farrther@session') || null
            return state.session
        },
        deleteSession: (state) => {
            sessionStorage.removeItem('farrther@session')
            state.session = null
        },

        // Loading
        showLoading: ({commit}) => {
            commit('setLoading', { show: true })
        },
        hiddenLoading: ({commit}) => {
            commit('setLoading', { show: false })
        },
        lostConection: ({commit}) => {
            commit('setLoading', { 
                show: true,
                title: 'Oops...',
                subtitle: 'We couldnt perform the action, wait a moment and try again'
            })
        },

        // Alerts
        showAlert: ({commit}, message) => {
            commit('setAlert', {
                open: true,
                message
            })
        },
        showError: ({commit}, message) => {
            commit('setAlert', {
                open: true,
                message,
                variant: 'danger'
            })
        }
    },
    modules: {
        login,
        // Catalogs
        countries,
        states,
        cities,
        languages,
        colors,
        vehicle_brands,
        vehicle_models,
        amenities, 
        partner_categories, 
        services,
        skills,
        faqs,
        faq_sections,
        users,
        travellers,
        roles,
        banks,
        documents,
        local_guides,
        partners,
        experiences_categories,
        experiences_durations,
        experiences,
        support_chat,
        currencies,
        reservations,
        reports
    },
    plugins: [
        //createLogger()
    ]
})
