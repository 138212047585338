<template>
    
        <button @click.stop="open_menu = !open_menu"
                class="btn flex-start-center border-0 custom_size custom-hover d-flex"
                type="button">

            <img style="width: 20px;" :src="gear" alt="settings">

                <span class="ms-2 text-capitalize">
                    Settings
                </span>

        </button>

        <div class="dropmenu_wrapper" v-if="open_menu">
            <span @click.stop="openMenu(index)"  v-for="(btn, index) in local_btns" :key="`index-btn_${index}`"
                    class="menu-btn">
                {{ btn.label }}

                <div class="submenu-wrapper" v-if="btn.open"
                    @mouseleave="btn.open = false">
                    <button @click.stop="router.push({ name: item.route })"
                        v-for="(item, index) in btn.submenu" :key="`submenu_${index}`"
                        class="btn flex-start-center border-0 d-flex"
                        type="button">
                        {{ item.label }}
                    </button>
                </div>

            </span>
        </div>
</template>

<script setup>
    import gear from "@/assets/icons/sidebar/gear.svg";
    import {ref} from "vue";
    import {useRouter} from "vue-router";

    const props = defineProps({
        btns: {
            type: Array,
            required: false,
            default: () => ([])
        }
    })

    //variables
    const open_menu = ref(false);
    const local_btns = ref([]);

    //hooks
    const router = useRouter();

    //methods
    const openMenu = (index) => {
        let already_open_btn = local_btns.value.findIndex((btn) => btn.open === true);

        if(index === already_open_btn) {
            local_btns.value[index].open = false;
        }

        else if(already_open_btn > -1){
            local_btns.value[already_open_btn].open = false;
            local_btns.value[index].open = true;
        }

        else local_btns.value[index].open = already_open_btn !== index;


    }


    //vue life cycle

    local_btns.value = props.btns;

</script>

<style lang="scss" scoped>
    @use 'sass:color';
    @import '../assets/scss/minxin.scss';
    

    .context-menu-wrapper {
        transition: 0.4s all;
	    scrollbar-color: cadetblue;
	    scrollbar-width: thin;


        .dropmenu_wrapper {
	        
	        @include downPixels(1440px) {
		        max-height: 150px;
		        overflow-y: scroll;
	        }

            .menu-btn {
                color: white;
                display: block;
                height: 35px;
                padding-left: 40px;
                padding-top: 5px;
                font-family: 'Poppins', sans-serif;
                font-weight: bold;
                cursor: pointer;
                width: 100%;
                margin-top: 10px;
                border-radius: 10px;
                position: relative;

                &:hover {
                    background-color: color.adjust(#133831, $lightness: -5%);
                }

                .submenu-wrapper {
                    width: 200px;
                    display: flex;
                    flex-direction: column;
                    background-color: white;
                    border-radius: 10px;
                    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
                    position: absolute;
                    right: -115%;
                    top: -150%;

                    button {
                        flex-grow: 1;
                        text-align: start;
                        height: 40px;
                        color: black !important;
                        background-color: white;
                        border: none;
                        transition: 0.4s all;

                        &:first-of-type {
                            border-top-left-radius: 10px;
                            border-top-right-radius: 10px;
                        }

                        &:last-of-type {
                            border-bottom-left-radius: 10px;
                            border-bottom-right-radius: 10px;
                        }

                        &:hover {
                            background-color: var(--bs-primary);
                            color: white !important;
                        }
                    }
                }
            }
        }
    }



</style>